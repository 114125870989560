













import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { IClasshourTool } from '@xt/client'

@Observer
@Component
export default class WenfangComp extends Vue {
  @Prop({ default: () => [] }) dataSource: IClasshourTool[]
}
