






























import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import LazyImg from '@xt/www/components/lazyImg/index.vue'

@Observer
@Component({
  components: {
    LazyImg
  }
})
export default class HomeworkDetailBannerComp extends Vue {
  @Prop({ default: () => [] }) pictureList: Array<{
    originalUrl: string
    waterUrl: string
    originHeight: number
    originWidth: number
  }>

  currentIndex = 0

  @Ref('swiperWrapper')
  swiperWrapper: HTMLDivElement

  onPrevSwiper() {
    if (this.currentIndex === 0) return
    this.currentIndex = this.currentIndex - 1
    this.changeSwiper()
  }

  onNextSwiper() {
    if (this.currentIndex === this.pictureList.length - 1) return
    this.currentIndex = this.currentIndex + 1
    this.changeSwiper()
  }

  changeSwiper() {
    this.swiperWrapper.style.transform = `translateX(-${this.$px2rem(this.currentIndex * 660)})`
  }

  onImagePreview(index: number) {
    this.$showViewer(
      this.pictureList.map(v => v.waterUrl),
      index
    )
  }
}
