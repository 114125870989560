














































import { Component, Vue, Prop, Watch, Ref, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import EmojiComp from '@xt/www/components/emoji/index.vue'
import StickerSelect from '@xt/www/components/stickerSelect/index.vue'
import { useOnClickOutsilde } from '@xt/client/hooks/useOnClickOutside'
import { getStickerPathByPattern } from '@xt/client/utils/sticker'

@Observer
@Component({
  name: 'FooterInput',
  components: {
    EmojiComp,
    StickerSelect
  }
})
export default class FooterInput extends Vue {
  @Prop({ required: true }) visible: boolean
  @Prop({ default: '和桐学交流一下' }) placeholder: string
  // 给某个人的回复，
  @Prop({}) sendTo: string

  message: string = ''
  selectionStart = 0
  visibleEmoji = false
  visibleSticker = false
  stickers: string[] = []

  @Ref('emoji-box')
  emojiBox: HTMLDivElement

  @Ref('sticker-box')
  stickerBox: HTMLDivElement

  @Ref('input-textarea')
  inputTextarea: any

  onVisibleEmoji(visible: boolean) {
    this.visibleEmoji = visible
  }

  onVisibleSticker(visible: boolean) {
    this.visibleSticker = visible
  }

  messageBlurHandler() {
    const node = this.inputTextarea.$el as HTMLTextAreaElement
    this.selectionStart = typeof node.selectionStart === 'number' ? node.selectionStart : this.message.length
  }

  chooseEmojiHandler(emoji: string) {
    this.message = this.message.slice(0, this.selectionStart).concat(emoji).concat(this.message.slice(this.selectionStart))
    this.selectionStart += emoji.length
  }

  chooseStickerHandler(stickerPattern) {
    // 最多可以选择3张表情
    if (this.stickers.length > 2) {
      this.$message.error('不能选更多了 😮‍💨')
      return
    }

    this.stickers.push(stickerPattern)
    this.onVisibleSticker(false)
  }

  @Watch('visibleEmoji')
  changeVisibleEmoji(visible: boolean) {
    const { resetClickHandler, recoverClickHandler } = useOnClickOutsilde(this.emojiBox, () => {
      this.onVisibleEmoji(false)
    })
    if (visible) {
      recoverClickHandler()
    } else {
      resetClickHandler()
    }
  }

  @Watch('visibleSticker')
  changeVisibleSticker(visible: boolean) {
    const { resetClickHandler, recoverClickHandler } = useOnClickOutsilde(this.stickerBox, () => {
      this.onVisibleSticker(false)
    })
    if (visible) {
      recoverClickHandler()
    } else {
      resetClickHandler()
    }
  }

  @Watch('visible')
  hanldeHide(isShow) {
    if (!isShow) {
      this.message = ''
      this.stickers = []
    } else {
      setTimeout(() => {
        if (this.$refs['input-textarea']) {
          ;(this.$refs['input-textarea'] as HTMLTextAreaElement).focus()
        }
      }, 300)
    }
  }

  removeSticker(stickerPath) {
    this.stickers = this.stickers.filter(path => path !== stickerPath)
  }

  getSelectSticker(pattern: string) {
    return getStickerPathByPattern(pattern)
  }

  get textareaPlaceholder() {
    return this.sendTo ? `发送给：${this.sendTo}` : this.placeholder ? this.placeholder : '和桐学交流一下'
  }

  handleCancel() {
    this.$emit('cancel')
  }

  handleSend() {
    this.$emit('confirm', { message: this.message, sticker: this.stickers.join(',') })
  }

  created() {}

  mounted() {}

  destroyed() {}
}
