














































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import loadsh from 'lodash'
import { EnumHomeworkTaskType, IClasshourTaskDataSource } from '@xt/client'
import { UploadFileType } from '../index.vue'
import EmojiComp from '@xt/www/components/emoji/index.vue'
import { useOnClickOutsilde } from '@xt/client/hooks/useOnClickOutside'

@Observer
@Component({
  components: {
    EmojiComp
  }
})
export default class HomeworkSubmitComp extends Vue {
  @Prop() activeHomeworkTask: IClasshourTaskDataSource | null
  @Prop({ default: () => [] }) fileList: Array<UploadFileType>
  @Prop({ default: 'homework' }) popupType: 'homework' | 'daily'

  title = ''
  content = ''
  selectionStart = 0
  public = true
  visibleEmoji = false

  @Ref('emoji-box')
  emojiBox: HTMLDivElement

  @Ref('input-textarea')
  inputTextarea: any

  get oss() {
    return this.$store.$OSS
  }

  get HomeworkTaskStore() {
    return this.$store.$storeHomework.HomeworkTask
  }

  get ClasshourStore() {
    return this.$store.$storeCourse.Details.Map.Details
  }

  get topTitle() {
    return this.popupType === 'homework' ? '作业内容' : '日记内容'
  }

  get contentPlaceholder() {
    return this.popupType === 'homework' ? '沉淀笔墨之美，乐享每日进益' : '期待你有一场精彩的蜕变之旅'
  }

  get bothAllRequired() {
    if (!this.activeHomeworkTask) return true
    return this.activeHomeworkTask.homeworkType === EnumHomeworkTaskType.PictureContentRequire
  }

  get pictureRequired() {
    if (this.bothAllRequired) return true
    return this.activeHomeworkTask.homeworkType === EnumHomeworkTaskType.PictureRequire
  }

  get fileUploadFinish() {
    return !this.fileList.some(item => item.status === 'uploading')
  }

  get fileUploadExistFail() {
    return this.fileList.some(item => item.status === 'error' || item.status === 'failed')
  }

  get contentRequired() {
    if (this.popupType === 'daily') return false
    if (this.bothAllRequired) return true
    return this.activeHomeworkTask.homeworkType === EnumHomeworkTaskType.ContentRequire
  }

  get disabledPublish() {
    if (this.popupType === 'daily') {
      return !this.fileList.length
    }
    return (
      (this.bothAllRequired && (!this.fileList.length || !this.fileUploadFinish) && !this.content) ||
      (this.pictureRequired && (!this.fileList.length || !this.fileUploadFinish)) ||
      (this.contentRequired && !this.content)
    )
  }

  onStopPropagation(e: Event) {
    e.stopPropagation()
  }

  onUploadChange(fileList: UploadFileType[]) {
    this.fileList = fileList
  }

  onVisibleEmoji(visible: boolean) {
    this.visibleEmoji = visible
  }

  contentBlurHandler() {
    const node = this.inputTextarea.$el as HTMLTextAreaElement
    this.selectionStart = typeof node.selectionStart === 'number' ? node.selectionStart : this.content.length
  }

  chooseEmojiHandler(emoji: string) {
    this.content = this.content.slice(0, this.selectionStart).concat(emoji).concat(this.content.slice(this.selectionStart))
    this.selectionStart += emoji.length
    const node = this.inputTextarea.$el as HTMLTextAreaElement
    node.focus()
  }

  async onPublish() {
    if (this.contentRequired && !/^[\s\S]*.*[^\s][\s\S]*$/.test(this.content)) {
      this.$msg('请输入内容')
      return
    }

    if (this.pictureRequired) {
      if (!this.fileList.length) {
        this.$msg('请上传图片')
        return
      }
      if (!this.fileUploadFinish) {
        this.$msg('图片还在上传中')
        return
      }
      if (this.fileUploadExistFail) {
        this.$msg('请删除上传失败的图片')
        return
      }
    }

    const data = {
      title: this.title,
      content: this.content,
      contentLength: this.content.length,
      fileList: this.fileList,
      public: this.public,
      pictures: this.fileList
        .map(v => v.response)
        .map(v => ({
          ...v,
          originalUrl: v.originUrl,
          originUrl: v.originUrl,
          originWidth: v.originalWidth,
          originHeight: v.originalHeight
        }))
        .filter(loadsh.isObject),
      onReset: this.onReset
    }

    this.$emit('publish', data)
  }

  onReset() {
    this.content = ''
    this.title = ''
    this.public = true
    this.visibleEmoji = false
  }

  @Watch('visibleEmoji')
  changeVisibleEmoji(visible: boolean) {
    const { resetClickHandler, recoverClickHandler } = useOnClickOutsilde(this.emojiBox, () => {
      this.onVisibleEmoji(false)
    })
    if (visible) {
      recoverClickHandler()
    } else {
      resetClickHandler()
    }
  }

  created() {
    try {
      this.oss.onPolicy(0, 1)
    } catch (error) {}
  }
}
