








import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { Bind, Debounce, Throttle } from 'lodash-decorators'

@Observer
@Component({
  name: 'CommentListIndicator'
})
export default class CommentListIndicator extends Vue {
  @Prop({ required: true }) isLoading: boolean
  @Prop({ required: true }) noMoreData: boolean

  parent: HTMLDivElement

  @Throttle(300)
  @Bind
  handleScroll() {
    if (!this.parent) return

    const scrollHeight = this.parent.scrollHeight // 可滚动区域的高
    const scrollTop = this.parent.scrollTop // 已经滚动区域的高
    const clientHeight = this.parent.clientHeight // 可视区高度
    // 以滚动高度 + 当前视口高度  >= 可滚动高度 = 触底
    if (clientHeight + scrollTop + 1 >= scrollHeight) {
      // 滚动区域很高时会有误差只需加上1px即可
      // 此处可书写触底刷新代码
      console.log('触底')
      this.$emit('load-more')
    }
  }

  created() {}

  mounted() {
    const parent = document.querySelector('#content-scroll-target')
    if (parent instanceof HTMLDivElement) {
      console.log('创建ob', parent)
      this.parent = parent
      parent.addEventListener('scroll', this.handleScroll)
    }
  }

  destroyed() {}
}
