

















































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lodash from 'lodash'
import { EnumCouponStatus, EnumCouponType, EnumPreferentialType, EnumProductType } from '@xt/client/enums'
import { FormatCouponInfo, formatCouponInfo } from '@xt/client/utils/coupon'
import { CouponInfoListItem, CouponPackItem } from '@xt/client/types/coupon'
import CouponUseInfo from '@xt/www/components/coupon/CouponUseInfo.vue'
import ExchangePopup from '@xt/www/components/coupon/ExchangePopup.vue'
import ExchangeBindPopup from '@xt/www/components/coupon/ExchangeBindPopup.vue'
import ExchangeConfirmPopup from '@xt/www/components/coupon/ExchangeConfirmPopup.vue'
import ExchangeSuccessPopup from '@xt/www/components/coupon/ExchangeSuccessPopup.vue'
import ShareReceivePopup from '@xt/www/components/popup/shareReceive/index.vue'
import WenfangPopup from '@xt/www/components/popup/wenfang/index.vue'
import ExchangeCoursePopup from '@xt/www/components/coupon/ExchangeCousePopup.vue'

type VisibleType = 'receive' | 'bind_account' | 'confirm_receive' | 'success_receive' | 'course_exchange'

@Observer
@Component({
  components: {
    CouponUseInfo,
    ExchangePopup,
    ExchangeBindPopup,
    ExchangeConfirmPopup,
    ExchangeSuccessPopup,
    ShareReceivePopup,
    WenfangPopup,
    ExchangeCoursePopup
  }
})
export default class CouponBtnComp extends Vue {
  @Prop() dataSource: CouponPackItem
  @Prop({ default: 'coupon-pack' }) type: 'coupon-pack' | 'coupon-gift'

  couponInfo: FormatCouponInfo | null = null
  // 优惠券使用说明数据
  couponUseDesc = null
  visibleCouponDesc = false
  visibleType: VisibleType | null = null
  visibleShareCoupon = false
  visibleWenfang = false
  // 有赞详情
  youzanDetail: any = null

  get PageStore() {
    return this.$store.$storeTicket
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  get OrderStore() {
    return this.$store.$storeOrder
  }

  get PackStore() {
    return this.$store.$coupon.myPacks
  }

  get EnumPreferentialType() {
    return EnumPreferentialType
  }

  get EnumCouponStatus() {
    return EnumCouponStatus
  }

  get EnumCouponType() {
    return EnumCouponType
  }

  get unUsed() {
    return this.dataSource.status === EnumCouponStatus.UnUsed
  }

  get isWenfangCoupon() {
    return this.dataSource.couponType === EnumCouponType.PencilStore
  }

  get isExchangeCoupon() {
    return this.dataSource.couponType === EnumCouponType.Exchange
  }

  get isStudyBuffCoupon() {
    return this.dataSource.couponType === EnumCouponType.Companion
  }

  get sharePath() {
    return `/pages/coupon/share/user/index?couponCode=${this.dataSource.couponCode}`
  }

  get haveOwnedText() {
    if (!this.isSelfCoupon) {
      return '已赠送'
    }

    switch (this.dataSource.status) {
      // 是文房券 && 赠给了别人  如果是未使用  还会展示已使用
      case EnumCouponStatus.UnUsed:
      case EnumCouponStatus.Used:
        return '已使用'
      case EnumCouponStatus.InValid:
        return '已失效'
      case EnumCouponStatus.Expired:
        return '已过期'
    }
  }

  get showBtnGroup() {
    // 未使用 && 自己的券
    return this.unUsed && this.isSelfCoupon
  }

  /** 自己的券 没有赠给别人 */
  get isSelfCoupon() {
    return this.dataSource.ownerId === this.UserStore.UserInfo.id
  }

  /** 首次赠送 */
  get firstGiveCoupon() {
    return this.dataSource.ownerId === this.dataSource.memberId
  }

  /** 已领取：手机号 */
  get showReceivePhoneTip() {
    // 文房券 && 拥有人id和自己的id一致 && 已经领取了
    return this.isWenfangCoupon && this.isSelfCoupon && this.dataSource.wenfangReceived
  }

  get couponInfoList(): Array<CouponInfoListItem> {
    const result: Array<CouponInfoListItem> = []

    if (!this.couponInfo) return result

    // 券时间
    result.push({
      icon: 'iconcoupon_time',
      title: '使用时间',
      value: this.couponInfo.useTime
    })

    // 适用课程
    if (Array.isArray(this.toJS(this.dataSource.exchangeProducts)) && this.dataSource.exchangeProducts.length > 0) {
      result.push({
        icon: 'iconcoupon_course',
        title: '适用课程',
        value: this.dataSource.exchangeProducts.map(i => i.productName).join('、')
      })
    }

    // 回放延长卡不展示适用范围和使用限制
    if (this.dataSource.couponType !== EnumCouponType.Companion) {
      // 适用范围
      if (typeof this.dataSource.allowGiveUse === 'boolean') {
        result.push({
          icon: 'iconcoupon_scope',
          title: '适用范围',
          value:
            this.dataSource.couponType === EnumCouponType.PencilStore ? '暄桐文房微信店' : this.dataSource.allowGive ? '自买' : '自买、赠课'
        })
      }

      // 使用限制
      if (this.couponInfo.majorLimit) {
        result.push({
          icon: 'iconcoupon_limit',
          title: '使用限制',
          value: this.couponInfo.majorLimit
        })
      }
    }

    return result
  }

  onVisible(visibleType: VisibleType | null) {
    this.visibleType = visibleType
  }

  goUseCoupon() {
    if (this.isWenfangCoupon) {
      this.visibleWenfang = true
    } else if (this.isExchangeCoupon) {
      this.onVisible('course_exchange')
    } else if (this.isStudyBuffCoupon) {
      if (this.type === 'coupon-gift') {
        this.$emit('to-use')
      }
      this.$router.push({
        path: '/my'
      })
    } else {
      if (this.type === 'coupon-gift') {
        this.$emit('to-use')
      }
      const product = this.dataSource.exchangeProducts[0]
      if (product.productType === EnumProductType.fullstage) {
        this.$router.push({
          path: product.activityPageUrl
        })
        return
      }
      this.$router.push({
        path: `/commodity/${product.productId}`
      })
    }
  }

  goRecieveCoupon() {
    this.onVisible('receive')
  }

  clickUseDescHandler() {
    this.couponUseDesc = {
      coupon: this.dataSource,
      list: this.couponInfoList
    }
    this.visibleCouponDesc = true
  }

  async onSendSms(device: string, callback: () => {}) {
    try {
      if (device) {
        let res = await this.PageStore.sendMsgByPhone<boolean | { errCode: number }>(device)
        if (typeof res !== 'boolean' && 'errCode' in res) {
          if (res.errCode !== 1191100006 /** 验证码已发送，请查收 在Request层直接弹出来了，业务不弹 */) {
            this.$msg('获取验证码失败')
          }
          return
        }
        if (res) {
          callback()
          this.$msg('获取验证码成功', 'success')
        }
      }
    } catch (error) {
      if (lodash.eq(error, false)) {
        this.$msg(this.$EnumMessage.check_code_error)
      }
    }
  }

  async submitReceiveForm(device: string, verifyCode: string, callback: () => {}) {
    try {
      await this.PageStore.checkPhoneCode({ device, verifyCode })
      this.getYouzanDetail(device)
    } catch (error) {
      if (error === false) {
        this.$msg('验证码错误')
      }
    } finally {
      callback()
    }
  }

  async getYouzanDetail(device: string) {
    try {
      //检查是否在有赞有账号
      const resp = await this.PageStore.checkYouzan(device, this.dataSource.id)
      if (resp && !resp['yzOpenId']) {
        setTimeout(() => {
          this.onVisible('bind_account')
        }, 800)
        return
      }
      this.youzanDetail = resp
      this.onVisible('confirm_receive')
    } catch (error) {
      if (error === false) {
        this.$msg('验证码错误')
      }
    }
  }

  async onConfirmReceive(callback: () => {}) {
    try {
      await this.PackStore.onReceiveCoupon({
        avatar: this.youzanDetail.avatar,
        couponId: this.dataSource.id,
        memberPhoneNum: this.youzanDetail.userPhone,
        yzUserId: this.youzanDetail.yzOpenId
      })
      setTimeout(() => {
        // 主动修改该文房券状态  不调用接口
        this.$emit('receive', { id: this.dataSource.id, youzanDetail: this.youzanDetail })

        this.onVisible('success_receive')
        callback()
        this.$store.$storeUser.onGetUserInfo()
      }, 800)
    } catch (error) {
      this.$msg(error.errMsg)
      callback()
    }
  }

  async onConfirmExchangeCourse(productId: number, callback: () => void) {
    try {
      const resp = await this.OrderStore.onCouponPay({
        buyWay: 1,
        couponId: this.dataSource.id,
        platform: 1,
        productId
      })
      if (resp.orderNo) {
        // 主动修改该文房券状态  不调用接口
        this.$emit('exchange', this.dataSource.id)

        this.$msg('兑换成功', 'success')
        this.onVisible(null)
      }
      callback()
    } catch (error) {
      callback()
    }
  }

  created() {
    const timeType = this.isWenfangCoupon && !this.dataSource.wenfangReceived ? 'activity' : 'coupon'
    this.couponInfo = formatCouponInfo(this.dataSource, timeType)
  }
}
