import { BindAll } from 'lodash-decorators'
import { EnumApiHomework } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import { EnumMessage } from '../../languages'
import { MomentSourceType } from '../../enums'
/**
 * 晒作业
 * @export
 * @class ControllerHomeworkSunDrying
 * @extends {Pagination<any>}
 */
@BindAll()
export class ControllerHomeworkSunDrying extends Pagination<any> {
  constructor($ajax: AjaxBasics) {
    // console.warn("~~~~constructor ControllerHomeworkSunDrying")
    super($ajax, {
      url: EnumApiHomework.MomentList,
      key: 'id'
    })
  }
  /**
   * 发布一条作业
   */
  async onInstall(body: { title?; visibleRange?; content?; contentLength?; homeworkId?; pictures?; sourceId?; singleCourseId? }) {
    return this.$ajax.post(EnumApiHomework.MomentAdd, {
      ...body,
      sourceType: MomentSourceType.SunDrying
    })
  }
  /**
   * 添加浏览量
   */
  async onAddbrowsenum(momentId: string) {
    this.onUpdate(momentId, old => {
      old.viewCount++
      return old
    })
    return this.$ajax.put(EnumApiHomework.MomentAddbrowsenum, { momentId })
  }
  /**
   * 删除朋友圈
   * @param id 朋友圈id
   */
  async onDeleteMoment(id: string) {
    await this.$ajax.delete(EnumApiHomework.MomentDelete, { id })
    this.onRemove(id)
  }
  /**
   * 点赞
   * @param data
   */
  // async onLikes(data) {
  //   if (data.like) {
  //     throw EnumMessage.like_error
  //   }
  //   await this.$ajax.put(EnumApiHomework.MomentLike, {
  //     momentId: data.id,
  //     likeType: true,
  //   })
  //   this.onUpdate(data, (old) => {
  //     old.likeCount++
  //     old.like = true
  //     return old
  //   })
  // }
}
export default ControllerHomeworkSunDrying
