









import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class PageView extends Vue {
  @Prop({ required: true }) visible: boolean

  onCancel() {
    this.$emit('closeStep')
  }
}
