



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { IClasshourMaterialDataSource } from '@xt/client'

@Observer
@Component
export default class MaterialComp extends Vue {
  @Prop({ default: () => [] }) dataSource: IClasshourMaterialDataSource[]

  get oss() {
    return this.$store.$OSS
  }
  get ClasshourStore() {
    return this.$store.$storeCourse.Details.Map.Details
  }

  async onDownload(item: IClasshourMaterialDataSource) {
    try {
      const filePath = item.filePath
      if (!filePath) {
        this.$msg('下载失败')
        return false
      }
      this.ClasshourStore.onAddCourseMaterials(item.id)
      let url = await this.oss.onGetFilesUrl(filePath)
      this.onOpenBlock(url, encodeURIComponent(item.name))
    } catch (error) {
      console.log('Clin -> file: classhour.ts -> line 170 -> download -> error', error)
    }
  }
}
