




































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import EmojiComp from '@xt/www/components/emoji/index.vue'
import AtList from './at-list.vue'
import Question from './question.vue'

@Observer
@Component({
  components: {
    EmojiComp,
    AtList,
    Question
  }
})
export default class LivePageView extends Vue {
  word = ''
  visibleQARecord = false
  visibleEmoji = false
  visibleAtList = false
  selectionStart = 0
  isComposition = false

  get PageStore() {
    return this.$store.$genseeLive
  }

  @Ref('input-box')
  inputBox: any

  keyDownHandler(e: KeyboardEvent) {
    if (this.isComposition) return
    if (e.key === 'Enter') {
      if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
        this.word += '\n'
      } else {
        this.onSubmitChat()
      }
    }
  }

  compositionstartHandler() {
    this.isComposition = true
  }

  compositionendHandler() {
    this.isComposition = false
  }

  textFocusHandler() {
    window.addEventListener('keydown', this.keyDownHandler)
    window.addEventListener('compositionstart', this.compositionstartHandler)
    window.addEventListener('compositionend', this.compositionendHandler)
  }

  textBlurHandler() {
    const node = this.inputBox.$el as HTMLTextAreaElement
    this.selectionStart = typeof node.selectionStart === 'number' ? node.selectionStart : this.word.length
    window.removeEventListener('keydown', this.keyDownHandler)
    window.removeEventListener('compositionstart', this.compositionstartHandler)
    window.removeEventListener('compositionend', this.compositionendHandler)
  }

  chooseHandler(emoji: string) {
    this.word = this.word.slice(0, this.selectionStart).concat(emoji).concat(this.word.slice(this.selectionStart))
    this.selectionStart += emoji.length
  }

  async onSubmitChat() {
    const result = await this.PageStore.submitChatMessage(this.word)
    if (result) {
      this.$msg(result)
      return
    }
    this.word = ''
  }

  onVisible(visible: boolean, type: 'emoji' | 'at') {
    this.resetClickHandler()
    if (visible) {
      switch (type) {
        case 'emoji':
          this.visibleEmoji = visible
          break
        case 'at':
          this.visibleAtList = visible
          break
      }
      this.recoverClickHandler()
    }
  }

  clickHandler(e: MouseEvent) {
    const node = e.target as HTMLElement
    const id = node.offsetParent?.id
    if (id && id.startsWith('live-operation-trigger')) return
    this.resetClickHandler()
  }

  resetClickHandler() {
    this.visibleEmoji = false
    this.visibleAtList = false
    this.visibleQARecord = false
    document.removeEventListener('click', this.clickHandler)
  }

  recoverClickHandler() {
    document.addEventListener('click', this.clickHandler)
  }

  created() {}
  mounted() {}
  destroyed() {}
}
