





import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import FileUtil from '@xt/client/utils/file/file'

export type PreviewOptions = {
  type: 'homework' | 'picture'
  homeworkRequireArea: boolean
  index?: number
}

@Observer
@Component
export default class UploadComp extends Vue {
  @Prop({ default: () => [] }) fileList: any[]
  @Prop({ default: 9 }) maxFile: number

  get oss() {
    return this.$store.$OSS
  }

  get uploadProps() {
    return {
      accept: 'image/*',
      multiple: true,
      showUploadList: false,
      // 上传开启前获取认证
      beforeUpload: async (file, fileList) => {
        const index = lodash.findIndex(lodash.concat(this.fileList, fileList), ['uid', file.uid])
        if (index > this.maxFile - 1) {
          return new Promise((res, rej) => {
            rej()
          })
        }
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isLt20M) {
          this.$msg('图片超过20MB!')
          return new Promise((res, rej) => {
            rej()
          })
        }
        if (await FileUtil.isHeif(file)) {
          this.$msg(this.$EnumMessage.upload_img_heif_error)
          return new Promise((res, rej) => {
            rej()
          })
        }
        const Policy = this.oss.onPolicy(0, 1)
        await Policy
        return this.oss.beforeFileMD5(file)
      },
      // 上传需要的参数
      data: file => {
        return this.oss.beforeData(file)
      }
    }
  }

  get disabled() {
    return this.fileList.length >= this.maxFile
  }

  async onUploadChange({ fileList }) {
    let thisFileList = [...this.fileList]
    for (let k = 0; k < fileList.length; k++) {
      let v = fileList[k]
      // 使用draggable组件会出现clone，需要过滤掉
      if (v['clone']) continue
      let haveIndex = -1
      thisFileList.map((tv, tk) => {
        if (tv.uid === v.uid) {
          haveIndex = tk
        }
      })
      let data = lodash.assign(
        v,
        v.status === 'uploading'
          ? {}
          : {
              waterUrl: v.response.waterUrl ? v.response.waterUrl : this.$images.uploadError
            }
      )
      if (haveIndex != -1) thisFileList[haveIndex] = data
      else thisFileList.push(data)
    }
    this.$emit('change', [...thisFileList])
  }
}
