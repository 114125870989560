






























import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import draggable from 'vuedraggable'
import LazyImg from '@xt/www/components/lazyImg/index.vue'
import { PreviewOptions, UploadFileType } from '../index.vue'

@Observer
@Component({
  components: {
    LazyImg,
    draggable
  }
})
export default class PictureThumbComp extends Vue {
  @Prop({ default: () => [] }) fileList: UploadFileType[]
  @Prop({ default: -1 }) currentIndex: number
  @Prop({ default: true }) homeworkRequireArea: boolean

  drag = false
  get dragOptions() {
    return {
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'draggable-ghost',
      filter: '.xt-nine-img-cross'
    }
  }

  get images() {
    return [...this.fileList]
  }

  set images(value) {
    this.$emit('change', value)
  }

  getImageUrl(uploadFile: UploadFileType) {
    return this.ossProcessImg(uploadFile.waterUrl, 108, 144, true)
  }

  @Emit('preview')
  onHomeworkPreview(): PreviewOptions {
    return { type: 'homework', homeworkRequireArea: true }
  }

  @Emit('preview')
  onThumbPreview(index: number): PreviewOptions {
    return { type: 'picture', homeworkRequireArea: false, index }
  }

  @Emit('remove')
  onRemove(e: PointerEvent, file: UploadFileType) {
    e.stopPropagation()
    return file
  }

  onDragStart() {
    this.drag = true
    this.$emit('changeDraggingStatus', true)
  }

  onDragEnd() {
    this.drag = false
    this.$emit('changeDraggingStatus', false)
  }

  created() {}
}
