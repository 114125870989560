




















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HomeworkDetailBannerComp from './classhourPopup/components/homework-detail-banner.vue'
import HomeworkDetailContent from './classhourPopup/components/homework-detail-content.vue'
import { ContentItem } from './ContentCard.vue'
import { EnumVisibleRange } from './DailyPopup.vue'

@Observer
@Component({
  components: {
    HomeworkDetailContent,
    HomeworkDetailBannerComp
  }
})
export default class DailyDetailComp extends Vue {
  @Prop({ default: true }) isBack: boolean
  @Prop() activeDaily: ContentItem

  get PageStore() {
    return this.$store.$storeHomework.Details
  }

  // 当前作业是否有图
  get hasImage() {
    return this.activeDaily && !!this.activeDaily.pictures.length
  }

  get EnumVisibleRange() {
    return EnumVisibleRange
  }

  handleDelete(data: { momentId?: number }) {
    if (data.momentId) {
      this.$store.$storeHomework.SunDrying.onRemove(data.momentId)
    }
    this.$emit('close')
  }

  @Emit('toggle-view')
  goBack() {
    return { view: 'main' }
  }
}
