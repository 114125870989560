export const enum MomentSourceType {
  /** 备展日记 */
  ExhibitionDiary = 1,
  /** 备展花絮 */
  ExhibitionTidbits = 2,
  /** 晒作业 */
  SunDrying = 3,
  /** 文墨可爱 */
  WMKA = 4,
  /** 暄桐雅会 */
  XTYH = 5,
  /** 出版物 */
  CBW = 6
}
