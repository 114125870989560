













import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { Classhour } from '@xt/client'
import LiveStatusComp from './live-status.vue'

@Observer
@Component({
  components: {
    LiveStatusComp
  }
})
export default class LiveComp extends Vue {
  @Prop() dataSource: Classhour
}
