









import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MomentSourceType } from '@xt/client/enums'
import ContentCard from './ContentCard.vue'

type TabConfig = {
  name: string
  key: number
  img: string
  current: boolean
  tabParams: {
    tabId?: number
  }
}

@Observer
@Component({
  name: 'ContentWaterfall',
  components: {
    ContentCard
  }
})
export default class ContentWaterfall extends Vue {
  @Prop({ required: true }) currentTab: TabConfig

  loadFinish = true
  InfiniteEvent = null

  @Watch('currentTab', { immediate: true, deep: true })
  handleSwitchTab(config: TabConfig) {
    if (config) {
      this.loadFinish = true
      this.Pagination.onReset()
      // this.loadmore(this.InfiniteEvent)
    }
  }

  get hideTop() {
    // 仅在全部列表中展示置顶标签，作业和日记不展示
    return this.currentTab.key !== 0
  }

  get PageStore() {
    return this.$store.$storeHomework
  }
  get Pagination() {
    return this.PageStore.SunDrying
  }

  get id() {
    return this.$route.params.id
  }

  get body() {
    return { sourceId: this.id, sourceType: MomentSourceType.SunDrying, ...this.currentTab.tabParams }
  }

  // 加载更多数据
  loadmore(event) {
    if (this.loadFinish) {
      this.loadFinish = false
      this.Pagination.onLoading(this.body, {}, event)
    } else {
      this.InfiniteEvent = event
      // event.loaded();
    }
  }
  // 图片加载完成
  finish() {
    this.loadFinish = true
    this.InfiniteEvent && this.InfiniteEvent.loaded()
  }

  created() {}

  mounted() {}

  destroyed() {
    // 销毁数据
    this.PageStore.SunDrying.onReset()
  }
}
