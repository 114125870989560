





















































import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { UserCourseStudyProgress } from '@xt/client/types/course.d'
import HeaderImage from '@xt/www/components/headerImage/index.vue'

@Observer
@Component({
  name: 'CourseNav',
  components: { HeaderImage }
})
export default class CourseNav extends Vue {
  @Prop() studyProgress: UserCourseStudyProgress
  @Prop() qrcodeUrl: string

  get PageStore() {
    return this.$store.$storeCourse.Details
  }

  get ProductInfo() {
    return this.PageStore.dataSource.original.productInfo
  }

  get formatLearnEndTime() {
    if (!this.PageStore.Map.dataSource.learnEndTime) return ''

    return this.moment(this.PageStore.Map.dataSource.learnEndTime).format('YYYY年MM月DD日')
  }

  get courseProgress() {
    return `${this.ProductInfo.taskSchedule}/${this.ProductInfo.bizProdCount}`
  }

  handleBack() {
    this.$router.back()
  }

  created() {}

  mounted() {}

  destroyed() {}
}
