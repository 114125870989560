











































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import LazyImg from '@xt/www/components/lazyImg/index.vue'
import HeaderImage from '@xt/www/components/headerImage/index.vue'
import DailyPopup from './DailyPopup.vue'

const randomColors = ['#CBC3C6', '#C5CAC7', '#B8B6A2']

// 内容部分复用晒作业的数据结构
export type ContentItem = {
  id: string
  like: boolean
  likeCount: number
  memberId: string
  memberNickname: string
  pictures: Array<{ waterUrl: string; originHeight: number; originWidth: number }>
  sourceId: number
  bizId: number
  sourceType: number
  title: null | string
  content: string
  // 话题
  sourceDescription: string | null
  topic: string | null
  top: boolean
  createTime: number
  [key: string]: unknown
}

@Observer
@Component({
  name: 'ContentCard',
  components: {
    HeaderImage,
    LazyImg,
    DailyPopup
  }
})
export default class ContentCard extends Vue {
  // 不展示置顶标签
  @Prop({ default: false }) hideTop: boolean

  @Prop({ required: true }) item: ContentItem

  // 对于没有图片的内容，展示一个随机色块
  randomBgColor: string = ''
  visible = false

  get PageStore() {
    return this.$store.$storeHomework
  }

  // 有标题优先取标题，没有标题取正文，超出两行溢出隐藏
  get contentTitle() {
    return this.item.title ? this.item.title : this.item.content
  }

  get hasImageData() {
    return Array.isArray(this.toJS(this.item.pictures)) && this.item.pictures.length > 0
  }

  compactImageSize(originWidth, originHeight) {
    const limitWidth = 194
    const shouldHeigth = Math.floor((limitWidth * originHeight) / originWidth)

    return [limitWidth, shouldHeigth]
  }

  getImageURL(url, originWidth, originHeight) {
    // console.log(originWidth, originHeight, 194, 248, ...this.compactImageSize(originWidth, originHeight))

    const limitHeight = 248
    const [, shouldHeigth] = this.compactImageSize(originWidth, originHeight)

    if (shouldHeigth <= limitHeight) {
      return this.ossProcessImg(url, originWidth, originHeight)
    }

    return this.ossProcessImg(url, Math.floor(194 * 2), Math.floor(248 * 2), true)
  }

  async onLikes(event: PointerEvent, item) {
    event.stopPropagation()
    try {
      await this.PageStore.onLikes(item)
    } catch (error) {
      this.$msg(error)
    }
  }

  goDailyDetail() {
    this.visible = true
  }

  created() {
    if (!this.hasImageData) {
      this.randomBgColor = randomColors[Math.floor(Math.random() * randomColors.length)]
    }
  }

  mounted() {}

  destroyed() {}
}
