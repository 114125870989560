
















































import { Component, Vue, Emit, Ref, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import PictureBannerComp from './classhourPopup/components/picture-banner.vue'
import PictureThumbComp from './classhourPopup/components/picture-thumb.vue'
import UploadComp from './classhourPopup/components/upload.vue'
import HomeworkSubmitComp from './classhourPopup/components/homework-submit.vue'
import { EnumHomeworkBizType, EnumHomeworkTaskType, IClasshourTaskDataSource } from '@xt/client'
import { PreviewOptions, UploadFileType } from './classhourPopup/index.vue'

@Observer
@Component({
  components: {
    PictureBannerComp,
    PictureThumbComp,
    UploadComp,
    HomeworkSubmitComp
  }
})
export default class ClasshourViceComp extends Vue {
  @Prop({ default: true }) isBack: boolean
  @Prop() activeHomeworkTask: IClasshourTaskDataSource | null
  @Prop() classhourId: number

  homeworkRequireArea = true
  currentIndex = -1
  fileList: Array<UploadFileType> = []
  dragging = false
  publishLock = false
  draggingThumb = false

  @Ref('upload-box')
  uploadBox: HTMLDivElement

  @Ref('homework-submit-comp')
  homeworkSubmitComp: any

  get HomeworkTaskStore() {
    return this.$store.$storeHomework.HomeworkTask
  }

  get MomentStore() {
    return this.$store.$storeHomework.SunDrying
  }

  get pictureRequired() {
    if (!this.activeHomeworkTask) return true
    return [EnumHomeworkTaskType.PictureRequire, EnumHomeworkTaskType.PictureContentRequire].includes(this.activeHomeworkTask.homeworkType)
  }

  onChangeDragging(dragging: boolean) {
    if (this.draggingThumb) return
    this.dragging = dragging
  }

  onChangeDraggingStatus(dragging: boolean) {
    this.draggingThumb = dragging
  }

  onUploadChange(fileList: UploadFileType[]) {
    this.fileList = fileList
    if (this.currentIndex < 0) {
      this.homeworkRequireArea = false
      this.currentIndex = 0
    }
  }

  onStopPropagation(e: Event) {
    e.stopPropagation()
  }

  @Emit('toggle-view')
  goBack() {
    this.onReset()
    return { view: 'main' }
  }

  onToggleSwiper(index: number) {
    this.currentIndex = index
  }

  onRemovePicture(file: UploadFileType) {
    const fileList = [...this.fileList]
    this.fileList = fileList.filter(item => item.uid !== file.uid)
    if (this.homeworkRequireArea) return
    if (!this.fileList.length) {
      this.onThumbPreview({ type: 'homework', homeworkRequireArea: true })
    } else if (this.currentIndex > this.fileList.length - 1) {
      this.currentIndex = this.fileList.length - 1
    }
  }

  onThumbPreview(options: PreviewOptions) {
    const { type, homeworkRequireArea = true, index } = options
    this.homeworkRequireArea = homeworkRequireArea
    this.onToggleSwiper(type === 'picture' ? index : -1)
  }

  onChooseImage() {
    this.uploadBox?.click()
  }

  async onPublish(data: any) {
    if (this.publishLock) return
    try {
      this.publishLock = true
      await this.HomeworkTaskStore.onHomeworkAdd({
        homeworkTaskId: this.activeHomeworkTask.id,
        title: data.title,
        content: this.encodeHTML(data.content),
        contentNum: data.contentLength,
        publicVisible: data.public,
        picList: data.pictures,
        sun: false,
        bizType: EnumHomeworkBizType.Course
      })
      this.$msg('发布成功', 'success')
      data.onReset()
      await this.publishComplate()
    } catch {
      this.$msg('发布失败', 'error')
    }
    this.publishLock = false
  }

  async publishComplate() {
    await this.$store.$storeCourse.Details.Map.Details.onLoading({ id: this.classhourId })
    this.goBack()
    this.onReset()
  }

  onReset() {
    this.homeworkRequireArea = true
    this.currentIndex = -1
    this.dragging = false
    this.fileList = []
    this.publishLock = false
    this.homeworkSubmitComp?.onReset()
  }

  created() {}
}
