/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-09-25 16:01:26
 * @modify date 2020-09-25 16:01:26
 * @desc 课程地图页
 */
/// <reference types="./course" />
import lodash from 'lodash'
import { BindAll } from 'lodash-decorators'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { EnumApiCore, EnumApiCourse } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'
import { Classhour } from '../basics/classhour'
/**
 * 课时地图页 数据
 */
interface IClasshourMaps {
  bishanLight: number
  bishanTotal: number
  classhourCount: number
  classhourList: Array<Classhour>
  learnEndTime: number
  showReview: boolean
}

export enum EnumQABizType {
  /** 展览 */
  Show = 1,
  /** 课时 */
  Classhour = 2
}
export enum EnumQAQuestionNode {
  /** 展览首页 */
  ShowHome = 1,
  /** 备展 */
  ReadyShow = 2,
  /** 课时详情 */
  Classhour = 3
}
@BindAll()
export class CourseMap extends EntitiesBasics<IClasshourMaps> {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiCourse.CourseMap
    })
  }

  onMapValues(res) {
    const dataSource = lodash.map(res.classhourList, item => {
      return new Classhour(item) // onComputeClasshourState(item)
    })
    return lodash.merge(res, { classhourList: dataSource })
  }
  // onComputeState(item) {
  //   return item
  // }

  /**
   * 课程回顾
   * @type {*}
   * @memberof CourseMap
   */
  @observable CourseReview: any = {}
  /** 临时token */
  @observable CourseReviewToken: any = ''
  /**
   * 获取课程回顾
   * @type {*}
   * @memberof CourseMap
   */
  async onGetCourseReview(id, token?) {
    const res = await this.$ajax.get(token ? EnumApiCourse.CourseReviewToken : EnumApiCourse.CourseReview, { id, token })
    runInAction(() => {
      this.CourseReview = res
    })
    return res
  }
  /**
   * 获取课程回顾token
   */
  async onGetReviewToken(id) {
    const res = await this.$ajax
      .request({
        url: EnumApiCourse.CourseToken,
        method: 'GET',
        body: { id },
        responseType: 'text'
      })
      .toPromise()
    runInAction(() => {
      this.CourseReviewToken = res
    })
    return res
  }
  /**
   * 地图页详情
   * @memberof CourseMap
   */
  Details = new CourseMapDetails(this.$ajax)
  /**
   * 修改更新
   * @param key
   * @param value
   */
  @action
  onUpdateClasshourState(key: any) {
    const dataSource = this.dataSource
    const index = lodash.findIndex(dataSource.classhourList, this.getPredicate(key))
    if (index === -1) {
      return
    }
    lodash.updateWith(dataSource.classhourList, `[${index}]`, old => {
      return new Classhour(old.original)
    })
    this._dataSource = dataSource
    return dataSource
  }
  /**
   * 获取 lodash Predicate 参数
   * @param key
   */
  getPredicate(key: any): any {
    if (lodash.isObject(key)) {
      return ['id', lodash.get(key, 'id')]
    }
    return ['id', key]
  }
}
/**
 * 地图页详情
 */
class CourseMapDetails extends EntitiesBasics<any> {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {
      // 课程详情地址
      url: EnumApiCourse.CourseMapDetails,
      onMapValues: res => {
        res.classhourInfo = new Classhour(res.classhourInfo)
        return res
      }
    })
  }

  /**
   * 课时详情
   */
  @computed
  get classhourInfo(): IClasshourEntities {
    return lodash.get(this.dataSource, 'classhourInfo', {})
  }

  /**
   * 是否存在内容预告
   */
  @computed
  get hasContent() {
    return !lodash.isEmpty(this.classhourInfo.content)
  }

  /**
   * 作业
   * @readonly
   * @type {any[]}
   * @memberof CourseMapDetails
   */
  @computed
  get homeworks(): any[] {
    return lodash.get(this.dataSource, 'homeworkTaskList', [])
  }

  // 存在作业任务
  @computed
  get hasHomeworks() {
    return !lodash.isEmpty(this.homeworks)
  }
  /**
   * 文具
   * @readonly
   * @type {any[]}
   * @memberof CourseMapDetails
   */
  @computed
  get stationery(): any[] {
    return lodash.get(this.dataSource, 'toolList', [])
  }
  @computed
  get hasStationery() {
    return !lodash.isEmpty(this.stationery)
  }
  /**
   * 学习资料
   * @param classhourId
   */
  @computed
  get material(): any[] {
    return lodash.get(this.dataSource, 'materialList', [])
  }

  // 存在学习资料
  @computed
  get hasMaterial() {
    return !lodash.isEmpty(this.material)
  }
  /**
   * 去直播
   * @param key
   * @param checked
   */
  async onGolive(id) {
    const url = await this.$ajax.request<string>({ url: EnumApiCourse.CourseLive, body: { id }, responseType: 'text' }).toPromise()
    return url
  }
  /**
   * 增加资料下载量
   * @param id 资料id
   */
  async onAddCourseMaterials(id) {
    await this.$ajax
      .request<string>({
        url: EnumApiCourse.CourseMaterials,
        method: 'POST',
        body: { id },
        responseType: 'text'
      })
      .toPromise()
  }

  /** 课时提问 */
  async onClasshourQA(entities: {
    bizId: number
    bizType: EnumQABizType
    questionContent: string
    questionNode: EnumQAQuestionNode
    sourceDescription: string
  }) {
    await this.$ajax.post(EnumApiCore.homeworkQA, entities)
  }
  // async onGetMaterial(classhourId) {
  //   const res = await this.$ajax.post(EnumApiCourse.Coursematerial, {
  //     classhourId,
  //   })
  //   runInAction(() => {
  //     this.material = res
  //   })
  // }
}
export default CourseMap
