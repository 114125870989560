





















import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import DailyPopup from './DailyPopup.vue'

type TabConfig = {
  name: string
  key: number
  img: string
  current: boolean
}

@Observer
@Component({
  name: 'CourseTab',
  components: {
    DailyPopup
  }
})
export default class CourseTab extends Vue {
  @Prop({ required: true }) tabConfig: Array<TabConfig>

  visible = false

  @Emit('switch-tab')
  handleSwitchTab(tab: TabConfig) {
    return tab.key
  }

  created() {}

  mounted() {}

  destroyed() {}
}
