











































import { Component, Mixins, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import MapClasshour from './classhour.vue'
import MapDetails from './details.vue'
import MapReview from './review.vue'
import { MixinsCourseMap } from '@xt/client/mixins'
@Observer
@Component({
  components: { MapClasshour, MapDetails, MapReview }
})
export default class PageView extends Mixins(MixinsCourseMap) {
  // popover 容器
  getPopupContainer(triggerNode) {
    return this.$refs.content
  }

  @Emit('detail')
  onDetail() {}

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
