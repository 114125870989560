
































import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  name: 'HomeworkDetailInput',
  components: {}
})
export default class HomeworkDetailInput extends Vue {
  @Prop({ required: true }) disableAction: boolean
  @Prop({ required: true }) like: boolean
  @Prop({ required: true }) collect: boolean
  @Prop({ required: true }) likeCount: number
  @Prop({ required: true }) collectCount: number
  @Prop({ required: true }) placeholder: string
  @Prop({ default: '' }) momentId: string

  qrcodeURL: string = ''

  clickLike() {
    if (this.disableAction) return

    this.$emit('toggle-like')
  }

  clickCollect() {
    if (this.disableAction) return

    this.$emit('toggle-collect')
  }

  created() {}

  async mounted() {
    if (this.momentId) {
      this.qrcodeURL = await this.$store.$storeShow.getQrcodeUrl({
        page: 'pages/moments/pictures/index',
        query: `${(this, this.momentId)}_-1`,
        appletsType: 2,
        hyaline: true
      })
    }
  }

  destroyed() {}
}
