/**
 *  课程 api 枚举
 */
export enum EnumApiCourse {
  /** 课程列表 */
  CourseList = '/course/api/courses',
  /** 已购买课程 */
  CourseListHasbuy = '/course/api/courses/hasbuy',
  /** 用户学习数据 */
  StudyProgress = '/course/api/courses/study/progress/{id}',
  /** 课程详情 */
  CourseDetails = '/course/api/courses/{id}',
  /** 去上课/我的课程列表 */
  MyCourseList = '/course/api/courses/myself',
  /** 首页推荐课程 */
  CourseRecommend = '/course/api/courses/recommend',

  /** 课程购买协议 */
  CourseProtos = '/course/api/courses/{id}/proto',
  ProductProtos = '/product/api/product/{productId}/protoco',
  /** 同意课程购买协议 */
  CourseProtosSign = '/course/api/courseprotos/{id}/sign',
  /** 购买页面查询课程信息 */
  CouseBuyInfo = '/course/api/courses/{id}/orderinfo',
  CouseHasbuy = '/course/api/courses/{id}/hasbuy',

  /** 地图页/课时 */
  CourseMap = '/course/api/courses/{id}/map',
  /** 地图页详情/课时详情 */
  CourseMapDetails = '/course/api/classhours/{id}',
  /** 增加课时资料计数 */
  CourseMaterials = '/course/api/materials/{id}',
  /** 获取直播/回放地址 */
  CourseLive = '/course/api/classhours/{id}/live',
  /** 获取课程感想 get获取感想列表 post发布感想 */
  CourseThought = '/course/api/courses/{id}/comments',
  /** 发表课程感想 */
  CourseThoughtAdd = '/course/api/courses/comments',
  /** 我提交的感想 */
  MyCourseThought = '/course/api/courses/{id}/comments/myself',
  /** 是否可以发布课程感想 */
  CourseThoutCanWrite = '/course/api/courses/{id}/comments/canwrite',
  /** 课程回顾 */
  CourseReview = '/course/api/courses/{id}/review',
  CourseReviewToken = '/course/api/courses/token/{token}/review',
  /** 获取临时token */
  CourseToken = '/course/api/courses/{id}/temptoken',

  /** 展示互动K值校验 */
  GenseeCheck = '/course/api/gensee/check',
  /** 进入直播间回调 */
  GenseeLive = '/course/api/gensee/live',
  /** 进入回放回调 */
  GenseePlayback = '/course/api/gensee/playback',
  /** 课程加群弹窗 **/
  CourseGroupImg = '/course/api/courses'
  // 旧
  /** 课程介绍 */
  // CourseIntroduce = "/usercourse/courseIntroduce",
  // /** 学习资料  */
  // Coursematerial = "/coursematerial/list",
  // /** 课程回顾 */
  // /** 创建分享 临时 token */
  // CreatetempToken = "/activity/createtemptoken",
  // /** 感想 */
  // Thoughts = "/coursecomment/list",
  // /** 发感想 */
  // ThoughtsComment = "/coursecomment/comment",
}
