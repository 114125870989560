import $global from '@xt/client/store/global'

export function mapStickerPaths(text: string, spliter = ',') {
  return text
    .split(spliter)
    .filter(Boolean)
    .map(item => {
      const [groupName, stickerIndex] = item.split(':')

      return getStickerPath(groupName, stickerIndex)
    })
}

export function getStickerPath(groupName: string, stickerIndex: string) {
  return `${$global.static}/images/sticker/${groupName}/${stickerIndex}.svg`
}

export function getStickerCoverPath(groupName: string) {
  return `${$global.static}/images/sticker/${groupName}/cover.svg`
}

export function getStickerPattern(groupName: string, stickerIndex: string) {
  return `${groupName}:${stickerIndex}`
}

export function getStickerPathByPattern(pattern: string) {
  const [groupName, stickerIndex] = pattern.split(':')
  return getStickerPath(groupName, stickerIndex)
}
