

























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HeaderImage from '@xt/www/components/headerImage/index.vue'
import LazyImg from '@xt/www/components/lazyImg/index.vue'
import { EnumHomeworkLevel, IMemberHomeworkItem } from '@xt/client'

@Observer
@Component({
  name: 'HomeworkItemDetail',
  components: { HeaderImage, LazyImg }
})
export default class HomeworkItemDetail extends Vue {
  @Prop() dataSource: IMemberHomeworkItem
  @Prop() defaultHomeworkTitle: string

  get EnumHomeworkLevel() {
    return EnumHomeworkLevel
  }

  get isReview() {
    return this.dataSource.homeworkLevel !== EnumHomeworkLevel.NoAppraise
  }

  get firstUrl() {
    return this.dataSource.homeworkPicList[0]?.waterUrl
  }

  @Emit('homework-detail')
  onShowHomeworkDetail(e: PointerEvent) {
    e.stopPropagation()
    return this.dataSource
  }

  created() {}

  mounted() {}

  destroyed() {}
}
