import { observable } from 'mobx'
import { BindAll } from 'lodash-decorators'
import { AjaxBasics } from '@xt/client/helpers/ajaxBasics'
import { EntitiesBasics } from '@xt/client/entities/basics/entities'
import $global from '@xt/client/store/global'

type LocationItemType = {
  code: string
  name: string
}

export type ChinaLocationType = LocationItemType & {
  children: Array<LocationItemType>
}

type ForeignLocationType = {
  nameEN: string
  nameZH: string
  code: string
}

type LocationType = {
  china: Array<ChinaLocationType>
  foreign: Array<ForeignLocationType>
}

export type FormatLocationType = Array<{
  value: string
  label: string
  children: Array<{
    value: string
    label: string
  }>
}>

@BindAll()
export class ControllerLocation extends EntitiesBasics {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {})
  }

  @observable locationData: FormatLocationType = []

  async onGetLocationResource() {
    try {
      const resp: LocationType = await this.$ajax.get(
        `/mocks/location.json`,
        {},
        { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
        {
          target: $global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn'
        }
      )
      this.locationData = this.onFormatLocation(resp)
    } catch (error) {}
  }

  onFormatLocation(data: LocationType) {
    const china = data.china.map(province => {
      const children = province.children.map(city => {
        return { value: city.name, label: city.name }
      })
      return { value: province.name, label: province.name, children }
    })
    const foreign = data.foreign.map(country => {
      return { value: country.nameZH, label: country.nameZH }
    })
    return [
      {
        value: '国内',
        label: '国内',
        children: china
      },
      {
        value: '国外',
        label: '国外',
        children: foreign
      }
    ]
  }
}
