































import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HeaderImage from '@xt/www/components/headerImage/index.vue'
import { EnumVisibleRange } from '@xt/client/enums/homework'

@Observer
@Component({
  name: 'HomeworkDetailHeader',
  components: {
    HeaderImage
  }
})
export default class HomeworkDetailHeader extends Vue {
  @Prop({ required: true }) hasImage: boolean
  // 内容标题 可以为空
  @Prop({ default: '' }) title: string
  // 内容正文 可以为空
  @Prop({ default: '' }) content: string
  // 话题标签 可以为空
  @Prop({ default: '' }) tag: string
  // 内容提交时间
  @Prop({ required: true }) createTime: number

  @Prop({ required: true }) visibleRange: EnumVisibleRange

  @Prop({ required: true }) isSelf: boolean

  getPopupContainer() {
    return this.$refs['header-action'] as HTMLDivElement
  }

  get EnumVisibleRange() {
    return EnumVisibleRange
  }

  @Emit('switch-visilbe')
  switchVisible() {}

  @Emit('delete')
  handleDelete() {}

  created() {}

  mounted() {}

  destroyed() {}
}
