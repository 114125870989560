






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ExchangeBindPopup extends Vue {
  @Prop({ default: false }) visible: boolean

  @Emit('close')
  onCancel() {}
}
