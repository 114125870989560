






























import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  name: 'CourseInfoModal',
  components: { LazyImg }
})
export default class CourseInfoModal extends Vue {
  @Prop({ required: true }) visible: boolean

  loading = true
  loadErr = false

  get PageStore() {
    return this.$store.$storeCourse.Details
  }
  isEmpty(data) {
    return lodash.isEmpty(data)
  }

  onLoadImg() {
    this.loading = false
  }
  onLoadError() {
    this.loading = false
    this.loadErr = true
  }

  created() {}

  mounted() {}

  destroyed() {}

  onClose() {
    this.$emit('close')
  }
}
