export enum EnumHomeworkBizType {
  /** 直播课 */
  Course = 1,
  /** 展览 */
  Show = 2,
  /** 日课 */
  Activity = 3
}

export enum EnumVisibleRange {
  /** 私密 */
  Privacy = 1,
  /** 未知 */
  Unknow = 2,
  /** 公开 */
  Public = 3
}
