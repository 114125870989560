export function useOnClickOutsilde(root: Element, reset?: () => void) {
  function clickHandler(e: MouseEvent) {
    if (root.contains(e.target as Element)) return
    resetClickHandler()
  }

  function resetClickHandler() {
    reset && reset()
    document.removeEventListener('click', clickHandler)
  }

  function recoverClickHandler() {
    document.addEventListener('click', clickHandler)
  }

  return { resetClickHandler, recoverClickHandler }
}
