












































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { IClasshourTaskDataSource, IMemberHomeworkItem } from '@xt/client'
import HomeworkItemDetail from './homework-item-detail.vue'

@Observer
@Component({ components: { HomeworkItemDetail } })
export default class HomeworkComp extends Vue {
  @Prop({ default: () => [] }) dataSource: IClasshourTaskDataSource[]

  @Emit('show-homework')
  onSubmitHomework(item: IClasshourTaskDataSource) {
    return { view: 'vice', homeworkTask: item }
  }

  @Emit('show-homework')
  onHomeworkDetail(item: IMemberHomeworkItem) {
    return { view: 'vice', viceType: 'homework_detail', homework: item }
  }
}
