/**
 *  作业 api 枚举
 */
export enum EnumApiHomework {
  /**
   * 朋友圈列表
   */
  MomentList = '/moment/api/moments/{sourceType}/{sourceId}',
  /**
   * 发朋友圈
   */
  MomentAdd = '/moment/api/moments',
  /**
   * 朋友圈点赞
   */
  MomentLike = '/moment/api/moments/like/{momentId}',
  /**
   * 我的朋友圈/我晒出的作业 3 === MomentSourceType.SunDrying
   */
  MyMomentList = '/moment/api/moments/myself',
  /**
   * 朋友圈添加浏览量
   */
  MomentAddbrowsenum = '/moment/api/moments/viewcount/{momentId}',
  /**
   * 朋友圈详情
   */
  MomentDetail = '/moment/api/moments/{id}',
  /**
   * 删除朋友圈
   */
  MomentDelete = '/moment/api/moments/{id}',

  /**
   * 朋友圈评论列表
   */
  MomentCommentList = '/moment/api/moments/{momentId}/comments',
  /**
   * 带层级的评论列表
   */
  MomentCommentListV2 = '/moment/api/v2/moments/{momentId}/comments',
  /**
   * 朋友圈发布评论
   */
  MomentComment = '/moment/api/moments/{momentId}/comments',
  /**
   * 删除朋友圈评论
   */
  MomentDeleteComment = '/moment/api/moments/comments/{id}',

  /**
   * 朋友圈 评论 点赞
   */
  MomentLikeComment = '/moment/api/moments/comments/like',

  //  交作业
  /**
   * 交作业
   */
  HomeworkAdd = '/homework/api/homeworks',
  /** 公开作业 */
  homeworkPublish = '/homework/api/homework/public/{id}',
  /** 私密作业 */
  homeworkPrivate = '/homework/api/homework/private/{id}',
  /**
   * 已提交交作业列表
   */
  HomeworkList = '/homework/api/homeworktasks/{id}/past',

  /**
   * 我的作业列表
   */
  MyHomeworkList = '/homework/api/homeworks/myself',
  /**
   * 删除我的作业
   */
  HomeworkDelete = '/homework/api/homeworks/{id}',
  /**
   * 晒出我的作业
   */
  HomeworkSun = '/homework/api/homeworks/{id}/sun',

  /**
   * 作业任务详情
   */
  HomeworkTaskDetail = '/homework/api/homeworktasks/{id}',

  /**
   * 作业详情
   */
  HomeworkDetail = '/homework/api/homeworks/{id}',

  /**
   * 助教回复列表
   */
  HomeworkComment = '/homework/api/homeworks/{id}/comments',
  /**
   * 助教回复列表 带回复总数
   */
  HomeworkCommentV2 = '/homework/api/v2/homeworks/{id}/comments',

  /**
   * 回复助教评语（已修改）
   */
  HomeworkReply = '/homework/api/homework/comments',

  /**
   * 作业详情点赞
   */
  HomeworkLike = '/homework/api/homeworks/{id}/like',

  /**
   * 修改作业可见性 老的接口
   */
  HomeworkVisible = '/homework/api/homeworks/{id}/visible?visibleRange={visibleRange}',

  // 新的公开作业
  HomeworkPublic = `/homework/api/homework/public/{id}`,
  // 新的私密作业
  HomeworkPrivate = `/homework/api/homework/private/{id}`,

  /**
   * 修改日记可见性
   */
  MomentVisible = `/moment/api/moments/visible/{id}?visibleRange={visibleRange}`,

  /**
   * 删除评论
   */
  HomeworkCommentDelete = '/homework/api/homework/comments/{id}',

  /**
   * 查询作业类型
   */
  HomeworkBizType = '/homework/api/homeworks/bizType',
  /**
   * 朋友圈收藏
   */
  MomentCollect = `/moment/api/moments/collect/{workId}?collect={collect}&collectType={collectType}`
}
