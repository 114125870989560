import lodash from 'lodash'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { MixinsCourseHelpers } from './helpers'
import { MixinsCourseClasshourDownTime } from './downTime'
import { Debounce } from 'lodash-decorators'
import { EnumCouponType } from '@xt/client/enums'
import { BaseCouponItem } from '@xt/client/types/coupon'

@Component
export class MixinsCourseNewClasshour extends Mixins(MixinsCourseHelpers, MixinsCourseClasshourDownTime) {
  @Prop({ default: false }) visible: boolean
  @Prop() classhourId: number
  // 用户在当前课程下可用的回放延长卡
  userAvailableCompanionCardList: BaseCouponItem[] = []
  // 展示回放延长卡使用弹窗
  showCompanionCardPop = false
  jumpDownloadPageLock = false

  get platform() {
    return this.$store.$global.platform
  }
  get id() {
    return {
      Mobile: this.$route.params.id || this.$route.query.id,
      PC: `${this.classhourId}`
    }[this.platform] as string
  }
  get oss() {
    return this.$store.$OSS
  }
  get Details() {
    return this.$store.$storeCourse.Details
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  get MapStore() {
    return this.Details.Map
  }
  get ClasshourStore() {
    return this.MapStore.Details
  }

  async onLoading(id: number) {
    if (id) {
      await this.ClasshourStore.onLoading({ id })
    }
  }

  /**
   * 去直播
   * @param isPlayback 是否是回放  用于区分跳转至直播间还是回放间
   */
  @Debounce(500)
  async onGolive(isPlayback: boolean, id?: any) {
    //   用户需要完善个人信息
    if (this.UserStore.onneedCompleteInfo()) {
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
      return
    }
    const targetRoom = isPlayback ? 'playback' : 'live'
    console.warn(isPlayback, 'targetRoom', targetRoom)

    id = id || this.id

    if (!id) return this.$msg('暂无直播时间')

    // 前置做一下拦截
    const { startTime, liveEnd, playbackStatus /** 回放 1， 不回放 2 */, playbackEndTime } = this.ClasshourStore.classhourInfo

    console.log(startTime, liveEnd, playbackEndTime, playbackStatus)

    // 不能进入直播间
    const preventGotoLive = this.moment(this.$ajax.serviceDate()).isBefore(this.moment(startTime).startOf('days'))
    console.warn(
      preventGotoLive,
      this.$ajax.serviceDate().format('YYYY-MM-DD HH:mm:ss'),
      this.moment(startTime).startOf('days').format('YYYY-MM-DD HH:mm:ss')
    )
    if (preventGotoLive) {
      return this.$msg('还没有到上课时间')
    }
    // 回放还没有生成
    if (liveEnd === true && playbackStatus === 0) {
      return this.$msg('回放正在生成中...')
    }
    // 回放已经结束
    const preventGotoPlayback = playbackEndTime && this.moment(this.$ajax.serviceDate()).isAfter(this.moment(playbackEndTime))
    if (preventGotoPlayback) {
      // 校验当前课时下是否有已经激活的回放延长卡
      const studyBuffEndTime = this.ClasshourStore.classhourInfo.original?.studyBuffEndTime
      const now = this.$ajax.serviceDate()
      if (studyBuffEndTime && now.isBefore(studyBuffEndTime)) {
        // 当前用户激活了回放延长卡且时间还没有过期

        this.gotoGenseeRoom(targetRoom, id)
        return
      }

      // 校验用户是否有可用的回放延长卡
      const cardList = await this.getUserCompanionCard()
      if (cardList.length > 0) {
        this.userAvailableCompanionCardList = cardList
        this.showCompanionCardPop = true
        return
      }
      return this.$msg('课程结束啦')
    }

    this.gotoGenseeRoom(targetRoom, id)
  }

  gotoGenseeRoom(targetRoom: 'playback' | 'live', id: string | number) {
    // 小程序独立打开一个页面
    if (this.$store.$global.isEmbeddedH5Page) {
      wx.miniProgram.navigateTo({
        url: `/pages/webview/index?p=${encodeURIComponent(`/${targetRoom}?classhour=${id}`)}`
      })
    } else {
      window.open(`/${targetRoom}?classhour=${id}`, '_blank')
    }
  }

  // 获取桐学回放延长卡数据
  async getUserCompanionCard() {
    const courseId = this.ClasshourStore.classhourInfo.original.courseId
    if (!courseId) return []

    const result = await this.Details.onOwnCompanionCard(EnumCouponType.Companion)

    return result.filter(item => this.isCompanionCardCanUse(courseId, item))
  }

  hideCompanionCardPop() {
    this.showCompanionCardPop = false
  }

  isCompanionCardCanUse(courseId: number | string, card: BaseCouponItem) {
    // 卡类型不适用
    if (card.couponType !== EnumCouponType.Companion) return false

    // 当前课程不适用
    if (card.exchangeProducts.every(prod => !lodash.isEqual(prod.bizId, courseId))) {
      return false
    }

    // 已经卡过期不适用？
    return true
  }

  // 作业按钮
  getSubmitText<T extends 'text' | 'button' | 'isSubmited' | 'color' | 'class'>(item, type: T) {
    // 作业提交状态（空表示:未提交 false:已提交 true:已评阅）
    let reviewed = lodash.isBoolean(item.reviewed) ? (item.reviewed ? 2 : 1) : 0
    switch (type) {
      case 'text':
        return ['未提交', '已提交', '已评阅'][reviewed]
      case 'button':
        return ['交作业', '查看', '查看'][reviewed]
      case 'isSubmited':
        return [false, true, true][reviewed]
      case 'color':
        return ['#cccccc', '#c4a57f', '#077578'][reviewed]
      case 'class':
        return ['grey', 'yellow', 'green'][reviewed]
    }
  }

  async onDownload(item) {
    try {
      let filePath = lodash.get(item, 'filePath')
      if (!filePath) {
        this.$msg('下载失败')
        return false
      }
      this.ClasshourStore.onAddCourseMaterials(item.id)
      let url = await this.oss.onGetFilesUrl(filePath)
      // 内嵌打开去微信小程序内下载文件
      if (this.$store.$global.isEmbeddedH5Page) {
        if (this.jumpDownloadPageLock) {
          console.log('命中了重复点击防御')
          return
        }

        this.jumpDownloadPageLock = true
        wx.miniProgram.navigateTo({
          url: `/pages/download/index?url=${encodeURIComponent(url)}&filename=${item.name}`,
          success: () => {
            setTimeout(() => {
              this.jumpDownloadPageLock = false
            }, 500)
          }
        })
        return
      }

      // 移动端
      if (this.platform === 'Mobile' && this.$store.$global.WechatBowser) {
        return this.$router.push({ name: 'download', query: { filePath: url, name: item.name } })
      }
      this.onOpenBlock(url, encodeURIComponent(item.name))
    } catch (error) {
      console.log('Clin -> file: classhour.ts -> line 170 -> download -> error', error)
    }
  }

  onToHomework(item) {
    // 用户需要完善个人信息
    if (this.UserStore.onneedCompleteInfo()) {
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
      return
    }

    this.$router.push({
      name: 'homework',
      query: {
        id: item.id
      }
    })
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
