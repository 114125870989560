import { HomeworkComment } from '@xt/client/types/course'
import { EnumApiHomework } from '@xt/client/api/homework'
import { AjaxBasics } from '@xt/client/helpers'
import { mapStickerPaths } from '@xt/client/utils/sticker'

type DataSource = {
  list: HomeworkComment[]
  hasMore: boolean
  isLoading: boolean
  pageSize: number
}

export class CommentLoader {
  private $ajax: AjaxBasics

  // 评阅数据
  private _reviewDataSource: DataSource = {
    list: [],
    pageSize: 10,
    isLoading: false,
    hasMore: true
  }

  // 评论数据
  private _commentDataSource: DataSource = {
    list: [],
    pageSize: 10,
    isLoading: false,
    hasMore: true
  }

  constructor($ajax: AjaxBasics) {
    this.$ajax = $ajax
  }

  // 获取评阅列表
  public async loadReview(data: {
    homeworkId: string | number
    rootCommentId: string | number
  }): Promise<{ hasMore: boolean; list: HomeworkComment[]; total: number }> {
    if (!this._reviewDataSource.hasMore) {
      throw new Error('没有更多数据了')
    }

    if (this._reviewDataSource.isLoading) {
      throw new Error('正在加载中')
    }

    // EnumApiHomework.HomeworkComment
    this._reviewDataSource.isLoading = true
    const response = await this.$ajax.get<{ commentTotal: number; comments: Array<any> }>(EnumApiHomework.HomeworkCommentV2, {
      id: data.homeworkId,
      rootCommentId: data.rootCommentId,
      pageSize: this._reviewDataSource.pageSize,
      lastId: this.getLastId(this._reviewDataSource.list)
    })
    this._reviewDataSource.isLoading = false

    if (response && !Array.isArray(response.comments)) {
      // TODO 报错处理
      throw new Error('数据错误')
    }

    // 已经没有数据了
    if (response.comments.length === 0) {
      this._reviewDataSource = {
        ...this._reviewDataSource,
        hasMore: false,
        isLoading: false
      }
    } else {
      const newReviewList = this.transformReviewList(response.comments)

      this._reviewDataSource = {
        ...this._reviewDataSource,
        list: this._reviewDataSource.list.concat(newReviewList),
        hasMore: newReviewList.length === this._reviewDataSource.pageSize
      }
    }

    return {
      hasMore: this._reviewDataSource.hasMore,
      list: this._reviewDataSource.list,
      total: response.commentTotal
    }
  }

  // 获取评论列表
  public async loadComment(data: {
    momentId: string
    rootCommentId: string | number
  }): Promise<{ hasMore: boolean; list: HomeworkComment[]; total: number }> {
    if (!this._commentDataSource.hasMore) {
      throw new Error('没有更多数据了')
    }

    if (this._commentDataSource.isLoading) {
      throw new Error('正在加载中')
    }

    this._commentDataSource.isLoading = true
    const response = await this.$ajax.get<{ commentTotal: number; comments: Array<any> }>(EnumApiHomework.MomentCommentListV2, {
      momentId: data.momentId,
      rootCommentId: data.rootCommentId,
      pageSize: this._commentDataSource.pageSize,
      lastId: this.getLastId(this._commentDataSource.list)
    })
    this._commentDataSource.isLoading = false

    if (!response || !Array.isArray(response.comments)) {
      // TODO 报错处理
      throw new Error('数据错误')
    }

    // 已经没有数据了
    if (response.comments.length === 0) {
      this._commentDataSource = {
        ...this._commentDataSource,
        hasMore: false,
        isLoading: false
      }
    } else {
      const newCommentList = this.transformCommentList(response.comments)

      this._commentDataSource = {
        ...this._commentDataSource,
        list: this._commentDataSource.list.concat(newCommentList),
        hasMore: newCommentList.length === this._commentDataSource.pageSize
      }
    }

    return {
      hasMore: this._commentDataSource.hasMore,
      list: this._commentDataSource.list,
      total: response.commentTotal
    }
  }

  // 获取子评论
  public async loadCommentChildren(data: {
    momentId: string | number
    rootCommentId: string | number
    lastId: string
  }): Promise<{ list: HomeworkComment[]; total: number }> {
    const response = await this.$ajax.get<{ commentTotal: number; comments: Array<any> }>(EnumApiHomework.MomentCommentListV2, {
      momentId: data.momentId,
      rootCommentId: data.rootCommentId,
      pageSize: 5,
      lastId: data.lastId
    })

    if (!response || !Array.isArray(response.comments)) {
      return
    }

    this._commentDataSource.list = this._commentDataSource.list.map(item => {
      if (item.commentId === data.rootCommentId) {
        const newChildren = this.transformCommentList(response.comments).map(item => {
          const { hasMore, children, ...rest } = item
          return rest
        })

        item.children = item.children.concat(newChildren)
        item.hasMore = newChildren.length === 5
      }

      return item
    })

    return { list: this._commentDataSource.list, total: response.commentTotal }
  }

  private stringify(msg: string) {
    return msg.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
  }

  // 发送评阅消息
  public async sendReviewMessage(
    message: string,
    options: { homeworkId: string; toCommentId?: string; stickersContent: string }
  ): Promise<{ success: boolean; list: HomeworkComment[] }> {
    const replyContent = this.stringify(message)

    const response = await this.$ajax.post<{ id: number }>(EnumApiHomework.HomeworkReply, {
      homeworkId: options.homeworkId,
      toCommentId: options.toCommentId,
      stickersContent: options.stickersContent,
      replyContent,
      replyContentNum: replyContent.length
    })

    if (response.id) {
      const data = this.transformReviewList([response])

      if (!this._reviewDataSource.hasMore) {
        this._reviewDataSource.list = this._reviewDataSource.list.concat(data)
      }

      return {
        success: true,
        list: this._reviewDataSource.list
      }
    }

    return {
      success: false,
      list: this._reviewDataSource.list
    }
  }

  // 发送评论消息
  public async sendCommentMessage(
    message: string,
    options: {
      momentId: string
      toCommentId: string | null
      toMemberId: string | null
      rootCommentId: string | number
      stickersContent: string
    }
  ): Promise<{ success: boolean; list: HomeworkComment[] }> {
    const safeMessage = this.stringify(message)

    const response = await this.$ajax.post<{ id: string; [k: string]: any }>(EnumApiHomework.MomentComment, {
      content: safeMessage,
      contentLength: safeMessage.length,
      ...options
    })

    if (response.id) {
      // 将数据插入到列表中，需要处理下发送的评论是1级评论还是2级评论
      // 如果options.rootCommentId === '0' 表示1级评论
      // 否则表示数据应该在commentId === options.rootCommentId数据项的children中
      const data = this.transformCommentList([response])

      if (options.rootCommentId === '0') {
        this._commentDataSource = {
          ...this._commentDataSource,
          list: [...data, ...this._commentDataSource.list]
        }
      } else {
        // 如果没有第二页数据时，才插入。避免二级评论存在分页时数据错乱
        this._commentDataSource.list = this._commentDataSource.list.map(item => {
          if (item.commentId === options.rootCommentId && item.hasMore === false) {
            console.warn('将这条评论插入到数组中展示出来')
            item.children = Array.isArray(item.children) ? item.children : []
            item.children.push(data[0])
          }
          return item
        })
      }

      return { success: true, list: this._commentDataSource.list }
    }

    return { success: false, list: this._commentDataSource.list }
  }

  // 删除评阅
  public async removeReviewItem(id: string): Promise<{ success: boolean; list: HomeworkComment[]; decrease: number }> {
    console.warn('删除评阅', id)
    const response = await this.$ajax.delete<boolean>(EnumApiHomework.HomeworkCommentDelete, {
      id
    })

    if (response === true) {
      this._reviewDataSource.list = this._reviewDataSource.list.filter(item => item.commentId !== id)
      return { success: true, list: this._reviewDataSource.list, decrease: 1 }
    }

    return { success: false, list: this._reviewDataSource.list, decrease: 0 }
  }

  // 删除评论
  public async removeCommentItem(id: string, rootId?: string): Promise<{ success: boolean; list: HomeworkComment[]; decrease: number }> {
    console.warn('~~~~删除评论', id, rootId)
    const response = await this.$ajax.delete<boolean>(EnumApiHomework.MomentDeleteComment, {
      id
    })
    let decrease = 0

    if (response === true) {
      const deleteItemInChildList = id !== rootId

      if (deleteItemInChildList) {
        decrease = 1

        this._commentDataSource.list = this._commentDataSource.list.filter(item => {
          if (item.commentId === rootId && Array.isArray(item.children)) {
            item.children = item.children.filter(child => child.commentId !== id)
            return item
          }

          return item
        })
      } else {
        this._commentDataSource.list = this._commentDataSource.list.filter(item => {
          if (item.commentId === id && Array.isArray(item.children)) {
            decrease = item.children.length + 1
          }

          return item.commentId !== id
        })
      }

      return { success: true, list: this._commentDataSource.list, decrease }
    }

    return { success: false, list: this._commentDataSource.list, decrease: 0 }
  }

  // 将后端返回的评阅数据转化为组件需要的数据
  private transformReviewList(originReviewList: Array<any>): HomeworkComment[] {
    const dataSource: HomeworkComment[] = originReviewList.map(data => {
      const children = Array.isArray(data.children)
        ? data.children.map(child => {
            return {
              commentId: child.id,
              nickname: child.memberNickname,
              memberId: child.memberId,
              content: child.content,
              rootCommentId: child.rootCommentId,
              pictures: Array.isArray(child.commentPictureList) ? child.commentPictureList : [],
              createTime: child.createTime,
              toCommentId: child.toCommentId,
              toMemberId: child.toMemberId,
              toMemberNickname: child.toMemberNickname,
              memberType: child.memberType,
              stickers: mapStickerPaths(child.stickersContent || '')
            }
          })
        : []

      return {
        children,
        commentId: data.id,
        nickname: data.memberNickname,
        memberId: data.memberId,
        content: data.content,
        rootCommentId: data.rootCommentId,
        pictures: Array.isArray(data.commentPictureList) ? data.commentPictureList : [],
        createTime: data.createTime,
        hasMore: children.length === 5,
        toCommentId: data.toCommentId,
        toMemberId: data.toMemberId,
        toMemberNickname: data.toMemberNickname,
        memberType: data.memberType,
        stickers: mapStickerPaths(data.stickersContent || '')
      }
    })

    return dataSource
  }

  // 将后端返回的评论数据转化为组件需要的数据
  private transformCommentList(originCommentList: Array<any>, withChildren?: boolean): HomeworkComment[] {
    const dataSource: HomeworkComment[] = originCommentList.map(data => {
      const children = Array.isArray(data.children)
        ? data.children.map(child => {
            return {
              commentId: child.id,
              nickname: child.memberNickname,
              memberId: child.memberId,
              content: child.content,
              rootCommentId: child.rootCommentId,
              pictures: Array.isArray(child.commentPictureList) ? child.commentPictureList : [],
              createTime: child.createTime,
              toCommentId: child.toCommentId,
              toMemberId: child.toMemberId,
              toMemberNickname: child.toMemberNickname,
              memberType: child.memberType,
              stickers: mapStickerPaths(child.stickersContent || '')
            }
          })
        : []

      return {
        children,
        commentId: data.id,
        nickname: data.memberNickname,
        memberId: data.memberId,
        content: data.content,
        rootCommentId: data.rootCommentId,
        pictures: Array.isArray(data.commentPictureList) ? data.commentPictureList : [],
        createTime: data.createTime,
        toCommentId: data.toCommentId,
        toMemberId: data.toMemberId,
        toMemberNickname: data.toMemberNickname,
        hasMore: children.length === 5,
        memberType: data.memberType,
        stickers: mapStickerPaths(data.stickersContent || '')
      }
    })

    return dataSource
  }

  private getLastId(list: Array<HomeworkComment>): string | undefined {
    if (list.length > 0) {
      return list[list.length - 1].commentId
    }

    return undefined
  }
}
