



















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { AjaxBasics, Classhour, EnumPlaybackStatus } from '@xt/client'
import { MixinsCourseNewClasshour } from '@xt/client/mixins'
import { BaseCouponItem } from '@xt/client/types/coupon'
import Downtime from './downtime.vue'
import PlaybackExtensionPopup from '@xt/www/components/coupon/PlaybackExtensionPopup.vue'

export enum EnumLiveStatus {
  /** 未初始化 */
  NoInit = 0,
  /** 直播未开始 */
  NotStart = 1,
  /** 直播中 */
  Living = 2,
  /** 回放生成中 */
  PlaybackGenerating = 3,
  /** 回放生成 */
  PlaybackGenerate = 4,
  /** 回放已结束 */
  PlaybackEnd = 5,
  /** 学习回顾 */
  StudyReview = 6
}

@Observer
@Component({
  components: {
    Downtime,
    PlaybackExtensionPopup
  }
})
export default class LiveStatusComp extends Mixins(MixinsCourseNewClasshour) {
  @Prop() classhourInfo: Classhour

  liveStatus: EnumLiveStatus = EnumLiveStatus.NoInit
  date: Record<'day' | 'hour' | 'minute' | 'seconds', number> = { day: 0, hour: 0, minute: 0, seconds: 0 }
  timer: NodeJS.Timer | null = null
  companionCardList: Array<BaseCouponItem> = []

  get EnumLiveStatus() {
    return EnumLiveStatus
  }

  get startTime() {
    return this.classhourInfo.original.startTime
  }

  get playbackEndTime() {
    return this.classhourInfo.original.playbackEndTime
  }

  get studyBuffEndTime() {
    return this.classhourInfo.original.studyBuffEndTime
  }

  get preventGotoLive() {
    return this.$ajax.serviceDate().isBefore(this.moment(this.startTime).startOf('days'))
  }

  formatTime(time: number) {
    return this.moment(time).format('YYYY.MM.DD HH:mm')
  }

  formatDate(time: number, format: 'all' | 'hms' = 'all') {
    const day = Math.floor(time / 1000 / 60 / 60 / 24)
    const hour = this.moment(time).utcOffset(0).get('hour')
    const minute = this.moment(time).utcOffset(0).get('minute')
    const seconds = this.moment(time).utcOffset(0).get('second')
    // console.log(day, hour, minute, seconds)
    return {
      day: format === 'hms' ? 0 : day,
      hour: format === 'hms' ? day * 24 + hour : hour,
      minute,
      seconds
    }
  }

  onStudyReview() {
    if (!this.companionCardList.length) {
      this.$msg('没有可用的学习回顾卡，刷新试试～')
      return
    }
    this.userAvailableCompanionCardList = this.companionCardList
    this.showCompanionCardPop = true
  }

  onJumpPlayback() {
    this.hideCompanionCardPop()
    this.gotoGenseeRoom('playback', this.classhourInfo.id)
  }

  async onRefreshData() {
    // 重新刷新下store数据
    await this.onLoading(this.classhourInfo.id)
    this.companionCardList = await this.getUserCompanionCard()
    this.init()
  }

  async init() {
    const currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    const { name, startTime, liveEnd, playbackEndTime, playbackStatus, studyBuffEndTime } = this.classhourInfo.original
    // 直播未开始，倒计时
    if (currentTime < startTime) {
      this.liveStatus = EnumLiveStatus.NotStart
      this.downtime(startTime)
      return
    }
    // 直播中
    if (!liveEnd) {
      this.liveStatus = EnumLiveStatus.Living
      return
    }
    // 回放生成中
    if (playbackStatus === EnumPlaybackStatus.NotPlayback) {
      this.liveStatus = EnumLiveStatus.PlaybackGenerating
      return
    }
    // 取消回放
    if (playbackStatus === EnumPlaybackStatus.CancelPlayback) {
      this.liveStatus = EnumLiveStatus.PlaybackEnd
      return
    }
    // 回放生成，开始倒计时（纯回放，没有使用伴学卡）
    if (!studyBuffEndTime && currentTime <= playbackEndTime) {
      this.liveStatus = EnumLiveStatus.PlaybackGenerate
      this.downtime(playbackEndTime)
      return
    }
    // 用了伴学卡&没有过期  开始倒计时
    if (studyBuffEndTime && currentTime <= studyBuffEndTime) {
      this.liveStatus = EnumLiveStatus.StudyReview
      this.downtime(studyBuffEndTime, 'hms')
      return
    }
    // 回放已结束（默认）
    this.companionCardList = await this.getUserCompanionCard()
    this.liveStatus = EnumLiveStatus.PlaybackEnd
  }

  downtime(endtime: number, format: 'all' | 'hms' = 'all') {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    this.timer = setInterval(() => {
      const currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      const diffTime = endtime - currentTime
      if (diffTime <= 0) {
        clearInterval(this.timer)
        this.timer = null
        this.init()
        return
      }
      this.date = this.formatDate(diffTime, format)
    }, 1000)
  }

  created() {
    this.init()
  }

  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }
}
