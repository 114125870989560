
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class LiveStatusComp extends Vue {
  @Prop() date: Record<'day' | 'hour' | 'minute' | 'seconds', number>
}
