






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import HeaderImage from '@xt/www/components/headerImage/index.vue'
import LottieAnimation from '@xt/www/components/lottieAnimation/index.vue'
import { LottieSucces } from '@xt/client/types/lottie'

type GiftInfo = { id: string; nickname: string; headerUrl?: string; device?: string }

@Component({
  components: { LottieAnimation, HeaderImage }
})
export default class GiftUserPop extends Vue {
  @Prop({ required: true }) defaultGiftInfo: GiftInfo
  @Prop({}) coverUrl: string

  confirmedGiftInfo: GiftInfo = { id: '', nickname: '', device: '' }
  giftInfo: GiftInfo = { id: '', nickname: '', device: '' }
  lottieAnim: LottieSucces | null = null

  actionConfirmLock = false

  @Watch('defaultGiftInfo', { immediate: true, deep: true })
  handleInit(info: GiftInfo) {
    // 待支付订单，填充只读数据
    if (!this.giftInfo.id && info.id) {
      this.giftInfo = { id: info.id, nickname: info.nickname, device: '' }
    }
  }

  btnStatus: 'confirm' | 'clear' = 'confirm'
  lastConfirmDevice = ''

  visible: boolean = false

  device: string = ''

  @Watch('giftInfo', { immediate: true, deep: true })
  handleGiftInfoChange(giftInfo) {
    if (this.btnStatus === 'confirm' && giftInfo.nickname) {
      this.btnStatus = 'clear'
    }
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  show() {
    // console.warn(
    //   'show',
    //   JSON.stringify(this.giftInfo, undefined, 2),
    //   JSON.stringify(this.confirmedGiftInfo, undefined, 2),
    //   this.device,
    //   this.lastConfirmDevice
    // )
    if (this.device !== this.lastConfirmDevice) {
      this.device = this.lastConfirmDevice
    }

    // 上次没有点击确认，关闭了弹窗
    if (this.confirmedGiftInfo.id === '' && this.giftInfo.id !== '') {
      this.btnStatus = 'confirm'
      this.lottieAnim?.reset()
    }

    if (this.defaultGiftInfo.id && this.giftInfo.id !== this.defaultGiftInfo.id) {
      this.giftInfo = this.defaultGiftInfo
    } else if (this.giftInfo.id !== this.confirmedGiftInfo.id) {
      this.giftInfo = this.confirmedGiftInfo
    }

    this.visible = true
  }

  hide() {
    this.visible = false
  }

  handleCancel() {
    this.hide()
  }

  hanldeLottieSuccess(data: LottieSucces) {
    console.log('hanldeLottieSuccess')
    this.lottieAnim = data
  }

  handleConfirm() {
    this.lastConfirmDevice = this.device
    this.confirmedGiftInfo = this.giftInfo
    this.$emit('confirm', this.giftInfo)
  }

  handleClick() {
    if (this.actionConfirmLock) return

    this.actionConfirmLock = true

    if (this.btnStatus === 'confirm') {
      // this.handleConfirm()
      this.handleSearchGiftUser()
    } else {
      // 清除数据
      // this.confirmedGiftInfo = { id: '', nickname: '', device: '' }
      this.giftInfo = { id: '', nickname: '', device: '' }
      this.btnStatus = 'confirm'
      this.device = ''
      // this.lastConfirmDevice = ''
    }

    setTimeout(() => {
      this.actionConfirmLock = false
    }, 350)
  }

  handleConfirmGift() {
    if (!this.giftInfo.id || this.btnStatus === 'confirm') return

    this.handleConfirm()
    this.handleCancel()
  }

  hanldeChangeInput() {
    if (this.btnStatus === 'clear') {
      this.btnStatus = 'confirm'
    }
  }

  async handleSearchGiftUser() {
    try {
      const isEmail = this.$Regulars.email.test(this.device)
      const isPhone = this.$Regulars.mobilePhone.test(this.device)

      if (isEmail || isPhone) {
        const resp = await this.UserStore.onBaseinfo(this.device)
        if (resp && 'id' in resp) {
          // 本平台账号
          if (this.$eqUser(resp.id)) {
            this.giftInfo = { id: '', nickname: '', device: '' }
            this.$msg('赠送人不可以是自己哦~')
            return
          } else {
            this.giftInfo = { id: resp.id, nickname: resp.nickname, device: this.device }
          }
        } else {
          // 非本平台账号
          this.giftInfo = { id: '0', nickname: this.device, device: this.device }
        }

        // 被赠人查询成功，执行动画
        this.lottieAnim?.play()
      } else {
        this.$msg('请输入正确的手机号或邮箱')
        this.giftInfo = { id: '', nickname: '', device: '' }
      }
    } catch {
      this.giftInfo = { id: '', nickname: '', device: '' }
    }
  }
}
