


























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HomeworkDetailHeader from './homework-detail-header.vue'
import HomeworkDetailComment from './homework-detail-comment.vue'
import HomeworkDetailInput from './homework-detail-input.vue'
import { ClickCommentPayload, HomeworkComment } from '@xt/client/types/course'
import { EnumVisibleRange } from '@xt/client/enums/homework'
import FooterInput from './footer-input.vue'
import HeaderImage from '@xt/www/components/headerImage/index.vue'
import { CommentLoader } from '@xt/client/utils/comment-loader/index'
import { HomeworkDetailDataType } from '@xt/client/types/homework'
import { MomentDetailDataType } from '@xt/client/types/moment'
import { EnumApiHomework } from '@xt/client/api/homework'
import CommentListIndicator from './comment-list-indicator.vue'
import { Bind, Debounce } from 'lodash-decorators'

type TabKey = 'comment' | 'review'

@Observer
@Component({
  name: 'HomeworkDetailContent',
  components: {
    HomeworkDetailComment,
    HomeworkDetailInput,
    HomeworkDetailHeader,
    FooterInput,
    HeaderImage,
    CommentListIndicator
  }
})
export default class HomeworkDetailContent extends Vue {
  // 展示返回按钮
  @Prop({ default: false }) showBackBtn: boolean
  // 当前内容是否有图片，兼容样式
  @Prop({ required: true }) hasImage: boolean
  // 日记id 查询评论列表 底部功能菜单置灰
  @Prop({ default: '' }) momentId: string
  // 作业id 查询评阅列表
  @Prop({ default: '' }) homeworkId: string

  homeworkDataSource: HomeworkDetailDataType | null = null
  momentDataSource: MomentDetailDataType | null = null

  get type(): 'homework' | 'daily' | 'homework-daily' {
    return this.homeworkId && this.momentId ? 'homework-daily' : this.momentId ? 'daily' : 'homework'
  }

  get dataSource(): HomeworkDetailDataType | MomentDetailDataType {
    if (this.type == 'homework') {
      return this.homeworkDataSource
    }

    return this.momentDataSource
  }

  get tag() {
    return this.type === 'homework' ? this.homeworkDataSource.homeworkTaskTitle : this.momentDataSource.sourceDescription
  }

  // 分页加载器
  loader: CommentLoader

  // 评论输入
  showInput = false

  // 点击回复按钮信息
  clickPayload: ClickCommentPayload | null = null

  // 评阅数据源
  reviewData: {
    list: HomeworkComment[]
    isLoading: boolean
    total: number
    hasMore: boolean
  } = {
    list: [],
    isLoading: false,
    hasMore: true,
    total: 0
  }

  // 评论数据源
  commetData: {
    list: HomeworkComment[]
    isLoading: boolean
    total: number
    hasMore: boolean
  } = {
    list: [],
    isLoading: false,
    hasMore: true,
    total: 0
  }

  get isSelfContent() {
    if (!this.dataSource) return false

    const selfUserId = this.$store.$storeUser.UserInfo.id

    return this.dataSource.memberId === selfUserId
  }

  get momentStatus(): { like: boolean; likeCount: number; collect: boolean; collectCount: number } {
    if (!this.momentDataSource)
      return {
        like: false,
        likeCount: 0,
        collect: false,
        collectCount: 0
      }

    return {
      like: this.momentDataSource.like,
      likeCount: this.momentDataSource.likeCount ?? 0,
      collect: this.momentDataSource.collect,
      collectCount: this.momentDataSource.collectCount ?? 0
    }
  }

  currentIndex = 0
  tabs = [
    {
      key: 'review' as TabKey,
      label: '评阅',
      show: false
    },
    {
      key: 'comment' as TabKey,
      label: '评论',
      show: false
    }
  ]

  get currentTab() {
    return this.tabs[this.currentIndex]
  }

  @Watch('tabs')
  handleTabsChange(currentTab: Array<{ key: TabKey; label: string; show: boolean }>) {
    // 分别加载每个tab下第一页数据 只会触发一次
    currentTab.forEach(tab => {
      if (tab.key === 'comment') {
        if (tab.show) {
          this.loadCommentMessage()
        }
      } else if (tab.key === 'review') {
        if (tab.show) {
          this.loadReviewMessage()
        }
      }
    })
  }

  handleShowInput() {
    // 如果当前是评阅且没有助教消息则不允许回复信息
    if (this.currentTab.key === 'review' && this.reviewData.list.length === 0) {
      return
    }

    this.showInput = true
  }

  switchTab(index: number) {
    // 切换tab时清除之前操作数据
    if (this.currentIndex !== index) {
      this.clickPayload = null
      this.showInput = false
    }

    this.currentIndex = index
  }

  async created() {
    console.warn('CREATE DEBUG', this.type, this.homeworkId, this.momentId)
    this.loader = new CommentLoader(this.$ajax)

    if (this.type === 'daily' || this.type === 'homework-daily') {
      this.momentDataSource = await this.$store.$storeHomework.Details.onLoading({ id: this.momentId })
    } else if (this.type === 'homework') {
      this.homeworkDataSource = await this.$store.$storeHomework.HomeworkDetail.onLoading({ id: this.homeworkId })
    }

    /**
     * 如果是自己的作业并且是直播课作业，展示两个tab(评论、评阅)
     * 分别请求/moment（评论）接口和/homework（评阅）接口
     *
     * 如果是自己的作业并且是非直播课作业，展示一个tab(评论)
     * 分别请求/moment（评论）接口
     *
     * 如果不是自己的作业，只展示一个tab(评论)
     * 只请求/moment（评论）接口
     */

    this.tabs = this.tabs
      .map(tab => {
        if (tab.key == 'review') {
          const visible = !!(this.isSelfContent && this.homeworkId)
          tab.show = visible
        } else if (tab.key === 'comment') {
          const visible = !!this.momentId
          tab.show = visible
        }

        return tab
      })
      .filter(tab => tab.show)
  }

  // 获取评阅数据
  async loadReviewMessage() {
    if (!this.homeworkId || !this.isSelfContent) return

    if (this.reviewData.isLoading) return

    try {
      this.reviewData.isLoading = true
      const { list, hasMore, total } = await this.loader.loadReview({ homeworkId: this.homeworkId, rootCommentId: '0' })

      this.reviewData.list = list
      this.reviewData.hasMore = hasMore
      this.reviewData.total = total
      console.debug('call loadReviewMessage', list, hasMore)
    } catch (e) {
      console.error(e)
      // if (e instanceof Error) this.$message.warn(e.message)
    } finally {
      this.reviewData.isLoading = false
    }
  }

  // 获取评论数据
  async loadCommentMessage() {
    if (!this.momentId) return

    if (this.commetData.isLoading) return

    try {
      this.commetData.isLoading = true
      const { list, hasMore, total } = await this.loader.loadComment({ momentId: this.momentId, rootCommentId: '0' })

      this.commetData.list = list
      this.commetData.hasMore = hasMore
      this.commetData.total = total
    } catch (e) {
      console.error(e)
      // if (e instanceof Error) this.$message.warn(e.message)
    } finally {
      this.commetData.isLoading = false
    }
  }

  hanldeClickCommentItem(data: ClickCommentPayload) {
    this.clickPayload = data
    this.$nextTick(() => {
      this.showInput = true
    })
  }

  async handleRemoveReviewItem({ id }: { id: string }) {
    const { success, list, decrease } = await this.loader.removeReviewItem(id)

    if (success) {
      this.$message.success('删除成功')
      this.reviewData.list = list
      this.reviewData.total = this.reviewData.total - decrease
    }
  }

  async handleRemoveCommentItem({ id, rootId }: { id: string; rootId?: string }) {
    const { success, list, decrease } = await this.loader.removeCommentItem(id, rootId)

    if (success) {
      this.$message.success('删除成功')
      this.commetData.list = list
      this.commetData.total = this.commetData.total - decrease
    }
  }

  async handleLoadMoreCommentChild(data: HomeworkComment) {
    if (!this.momentId) {
      console.error('handleLoadMoreCommentChild 找不到comentId')
      return
    }

    if (!data.children.length) {
      // never
      return
    }

    const lastId = data.children[data.children.length - 1].commentId

    const { list, total } = await this.loader.loadCommentChildren({ momentId: this.momentId, rootCommentId: data.commentId, lastId })
    console.log('~~~list', list)
    this.commetData.list = list
    this.commetData.total = total
  }

  @Debounce(1000, { leading: true, trailing: false })
  @Bind
  async handleConfirm({ message: _message, sticker }: { message: string; sticker: string }) {
    const message = _message.trim()
    if (!message) {
      this.$message.error('评论内容不能为空')
      return
    }

    //  评论字数不能大于300
    if (message.length >= 301) {
      this.$message.error('评论字数不能超过300')
      return
    }

    const tab = this.tabs[this.currentIndex]!

    if (tab.key === 'comment') {
      const { success, list } = await this.loader.sendCommentMessage(message, {
        momentId: this.momentId,
        toCommentId: this.clickPayload ? this.clickPayload.toCommentId : null,
        toMemberId: this.clickPayload ? this.clickPayload.toMemberId : null,
        // clickPayload不存在指的是回复当前内容，手动设置为'0'
        rootCommentId: this.clickPayload ? this.clickPayload.rootCommentId : '0',
        stickersContent: sticker
      })

      if (!success) {
        this.$message.error('发送失败')
        return
      } else {
        this.$message.success('发送成功')

        // 更新列表
        this.clickPayload = null
        this.commetData.list = list
        this.commetData.total = this.commetData.total + 1
        this.showInput = false
      }
    } else if (tab.key === 'review') {
      const { success, list } = await this.loader.sendReviewMessage(message, {
        homeworkId: this.homeworkId,
        toCommentId: this.clickPayload ? this.clickPayload.toCommentId : '',
        stickersContent: sticker
      })

      if (!success) {
        this.$message.error('发送失败')
        return
      } else {
        this.$message.success('发送成功')

        this.reviewData.list = list
        this.reviewData.total = this.reviewData.total + 1
        this.showInput = false
      }
    }
  }

  // 切换公开隐藏作业优先
  @Debounce(1000, { leading: true, trailing: false })
  @Bind
  async handleSwitchVisible() {
    try {
      let changed = false

      const targetVisibleRange =
        this.dataSource.visibleRange === EnumVisibleRange.Public ? EnumVisibleRange.Privacy : EnumVisibleRange.Public

      if (this.type === 'daily') {
        changed = await this.$ajax.put<boolean>(EnumApiHomework.MomentVisible, {
          id: this.momentId,
          visibleRange: targetVisibleRange
        })

        if (changed) {
          this.momentDataSource.visibleRange = targetVisibleRange
        }
      } else {
        changed = await this.$ajax.put(
          targetVisibleRange === EnumVisibleRange.Privacy ? EnumApiHomework.HomeworkPrivate : EnumApiHomework.HomeworkPublic,
          {
            id: this.homeworkId
          }
        )

        if (changed) {
          if (this.type === 'homework-daily') {
            this.momentDataSource.visibleRange = targetVisibleRange
          } else {
            this.homeworkDataSource.visibleRange = targetVisibleRange
          }
        }
      }

      if (changed) {
        this.$message.success('修改成功')
      }
    } catch (e) {
      console.warn('err', e)
    }
  }

  // 删除作业优先
  async handleDelete() {
    try {
      let deleted = false

      if (this.type === 'daily') {
        deleted = await this.$ajax.delete<boolean>(EnumApiHomework.MomentDelete, {
          id: this.momentId
        })
      } else {
        deleted = await this.$ajax.delete<boolean>(EnumApiHomework.HomeworkDelete, {
          id: this.homeworkId
        })
      }

      if (deleted) {
        this.$message.success('删除成功')
        this.$emit('deleted-scuess', { type: this.type, momentId: this.momentId, homeworkId: this.homeworkId })
      }
    } catch {}
  }

  async toggleLike() {
    if (!this.momentDataSource) return

    if (this.momentDataSource.like) {
      this.$message.error('你已经点过赞了')
      return
    }

    try {
      const response = await this.$ajax.put<boolean>(EnumApiHomework.MomentLike, {
        momentId: this.momentId
      })

      if (response) {
        this.momentDataSource.like = true
        this.momentDataSource.likeCount = typeof this.momentDataSource.likeCount === 'number' ? this.momentDataSource.likeCount + 1 : 1
        this.$message.success('点赞成功')
      }
    } catch {}
  }

  @Debounce(1000, { leading: true, trailing: false })
  @Bind
  async toggleCollect() {
    if (!this.momentDataSource) return

    try {
      const nextCollect = this.momentDataSource.collect ? 'false' : 'true'
      const response = await this.$ajax.put<boolean>(EnumApiHomework.MomentCollect, {
        workId: this.momentId,
        collect: nextCollect,
        // 晒作业日记 31
        collectType: 31
      })

      if (response === true) {
        this.momentDataSource.collect = nextCollect === 'true'

        const preCollectCount = typeof this.momentDataSource.collectCount === 'number' ? this.momentDataSource.collectCount : 0
        this.momentDataSource.collectCount = nextCollect === 'true' ? preCollectCount + 1 : preCollectCount - 1

        this.$message.success(nextCollect === 'true' ? '收藏成功' : '取消收藏成功')
      }
    } catch {}
  }

  async mounted() {}

  destroyed() {}
}
