import { render, staticRenderFns } from "./ExchangeSuccessPopup.vue?vue&type=template&id=6cb12f40&scoped=true&"
import script from "./ExchangeSuccessPopup.vue?vue&type=script&lang=ts&"
export * from "./ExchangeSuccessPopup.vue?vue&type=script&lang=ts&"
import style0 from "./ExchangeSuccessPopup.vue?vue&type=style&index=0&id=6cb12f40&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb12f40",
  null
  
)

export default component.exports