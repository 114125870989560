
































































































import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HeaderImage from '@xt/www/components/headerImage/index.vue'
import { ClickCommentPayload, HomeworkComment } from '@xt/client/types/course'
import { UserMemberTypeEnum } from '@xt/client/enums'

@Observer
@Component({
  name: 'HomeworkDetailComment',
  components: {
    HeaderImage
  }
})
export default class HomeworkDetailComment extends Vue {
  @Prop({ required: true }) data: HomeworkComment
  @Prop({ default: false }) disableCommentTo
  @Prop({ default: false }) isSelfContent

  @Emit('comment-to')
  handleComment(item: Omit<HomeworkComment, 'children' | 'hasMore'>): ClickCommentPayload {
    return {
      // 回复某条消息：如果当前消息有rootCommentId字段(字符串'0'也认为是不存在这个字段) 取该字段，否则取 id
      rootCommentId: !item.rootCommentId || item.rootCommentId === '0' ? item.commentId : item.rootCommentId,
      toCommentNickname: item.nickname,
      toCommentId: item.commentId,
      toMemberId: item.memberId
    }
  }

  @Emit('load-more-child')
  hanldeLoadMoreChild() {
    return this.data
  }

  @Emit('remove-item')
  handleRemoveComment(id: string) {
    return {
      id,
      rootId: this.data.commentId
    }
  }

  getMainContainer() {
    return this.$refs['main-comment-container'] as HTMLDivElement
  }

  getChildContainer(e: HTMLElement) {
    return e.parentElement
  }

  get currentLoginUserId() {
    return this.$store.$storeUser.UserInfo.id
  }

  get isAssistantComment() {
    return this.data.memberType === UserMemberTypeEnum.TeachingAssistant
  }

  isSelf(id: string) {
    return this.currentLoginUserId === id
  }

  get isParentSelfComment() {
    return this.isSelf(this.data.memberId)
  }

  created() {}

  mounted() {}

  destroyed() {}
}
