
























import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import moment from 'moment'
import LiveComp from './classhourPopup/components/live.vue'
import HomeworkComp from './classhourPopup/components/homework.vue'
import WenfangComp from './classhourPopup/components/wenfang.vue'
import QuestionComp from './classhourPopup/components/question.vue'
import MaterialComp from './classhourPopup/components/material.vue'
import { Classhour, EnumPlaybackStatus, IClasshourMaterialDataSource, IClasshourTaskDataSource } from '@xt/client'

@Observer
@Component({
  components: {
    LiveComp,
    HomeworkComp,
    WenfangComp,
    QuestionComp,
    MaterialComp
  }
})
export default class ClasshourMainComp extends Vue {
  @Prop({ default: () => {} }) dataSource: {
    classhourInfo: Classhour
    homeworks: IClasshourTaskDataSource[]
    material: IClasshourMaterialDataSource[]
    stationery: IClasshourMaterialDataSource[]
  }

  get EnumPlaybackStatus() {
    return EnumPlaybackStatus
  }

  get liveStartTime() {
    return moment(this.dataSource.classhourInfo.original.startTime).format('YYYY-MM-DD HH:mm')
  }

  get isPlayback() {
    return this.dataSource.classhourInfo.playbackStatus === EnumPlaybackStatus.Playback
  }

  @Emit('toggle-view')
  onShowHomework() {}
}
