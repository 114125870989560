










import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class EmojiComp extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: 8 }) column: number

  get PageStore() {
    return this.$store.$genseeLive
  }

  @Ref('emoji-container')
  emojiContainer: HTMLDivElement

  @Emit('close')
  onClose() {}

  @Emit('choose')
  chooseHandler(e: PointerEvent, emoji: string) {
    e.stopPropagation()
    this.onClose()
    return emoji
  }

  @Watch('visible')
  changeVisible(value: boolean) {}

  created() {}
  mounted() {}
  destroyed() {}
}
