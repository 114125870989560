/*
 * @Author: Erlin
 * @CreateTime: 2020-12-04 16:12:40
 * @LastEditors: Erlin
 * @LastEditTime: 2020-12-04 16:12:51
 * @Description: 作业任务
 */

import { BindAll } from 'lodash-decorators'
import { EnumApiHomework } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import { EntitiesBasics } from '../basics/entities'
import { onComputeState } from './helpers'
import { EnumHomeworkBizType } from '@xt/client/enums/homework'

/**
 * 我的作业任务
 * @export
 * @class ControllerTask
 * @extends {Pagination<any>}
 */
@BindAll()
export class ControllerTask extends Pagination<any> {
  constructor($ajax: AjaxBasics) {
    /** 已提交作业列表 */
    super($ajax, {
      url: EnumApiHomework.HomeworkList,
      key: 'id'
    })
  }
  /** 我的作业列表 */
  MyHomeworkList = new Pagination(this.$ajax, {
    url: EnumApiHomework.MyHomeworkList
  })

  /** 作业任务详情 */
  HomeworkTaskDetail = new EntitiesBasics(this.$ajax, {
    url: EnumApiHomework.HomeworkTaskDetail
  })

  /** 作业详情 */
  HomeworkDetail = new EntitiesBasics(this.$ajax, {
    url: EnumApiHomework.HomeworkDetail,
    onMapValues: onComputeState
  })

  /** 助教回复列表 */
  HomeworkComment = new Pagination(this.$ajax, {
    url: EnumApiHomework.HomeworkComment
  })

  /** 交作业 */
  async onHomeworkAdd(body: {
    title?: string
    content?: string
    contentNum?: number
    homeworkTaskId?: any
    publicVisible?: boolean
    picList: any[]
    sun: boolean
    bizType?: EnumHomeworkBizType
  }) {
    await this.$ajax.post(EnumApiHomework.HomeworkAdd, body)
  }

  /** 公开作业 */
  async onPublicHomework(id: number) {
    await this.$ajax.post(EnumApiHomework.homeworkPublish, { id })
  }

  /** 私密作业 */
  async onPrivateHomework(id: number) {
    await this.$ajax.put(EnumApiHomework.homeworkPrivate, { id })
  }

  /** 晒作业 */
  async onSunWork(id: number) {
    try {
      await this.$ajax.put(EnumApiHomework.HomeworkSun, { id })
      const updater = old => {
        old.suned = true
        return old
      }
      this.onUpdate(id, updater)
      this.MyHomeworkList.onUpdate(id, updater)
      this.HomeworkDetail.onUpdate(updater)
    } catch (error) {
      throw error
    }
  }

  /** 删除作业 */
  async onDelWork(id: number) {
    await this.$ajax.delete(EnumApiHomework.HomeworkDelete, {
      id
    })
    this.onRemove(id)
    this.MyHomeworkList.onRemove(id)
  }

  /** 回复助教 */
  async onReply(body: { courseId?; homeworkId?; replyContent?; replyContentNum?; toCommentId? }) {
    return this.$ajax.post(EnumApiHomework.HomeworkReply, body)
  }
}
export default ControllerTask
