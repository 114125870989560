






















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HomeworkDetailBannerComp from './classhourPopup/components/homework-detail-banner.vue'
import HomeworkDetailContent from './classhourPopup/components/homework-detail-content.vue'
import { IMemberHomeworkItem } from '@xt/client'
import { EnumVisibleRange } from './DailyPopup.vue'

@Observer
@Component({
  components: {
    HomeworkDetailContent,
    HomeworkDetailBannerComp
  }
})
export default class ClasshourHomeworkViceComp extends Vue {
  @Prop({ default: true }) isBack: boolean
  @Prop() activeHomework: IMemberHomeworkItem | null
  @Prop() classhourId: number

  get PageStore() {
    return this.$store.$storeHomework.HomeworkDetail
  }

  get MomentStore() {
    return this.$store.$storeHomework.Details
  }

  get EnumVisibleRange() {
    return EnumVisibleRange
  }

  // 当前作业是否有图
  get hasImage() {
    return this.activeHomework && !!this.activeHomework.homeworkPicList.length
  }

  @Emit('toggle-view')
  goBack() {
    return { view: 'main' }
  }

  handleDeleted() {
    if (!this.classhourId) return

    this.$store.$storeCourse.Details.Map.Details.onLoading({ id: this.classhourId })
    this.goBack()
  }

  async created() {}
}
