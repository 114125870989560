export enum BanType {
  Warn = 1,
  Ban = 2
}

export enum TargetType {
  Account = 1,
  Device = 2
}

export enum UserMemberTypeEnum {
  /**
   * 游客
   */
  Tourist = -1,
  /**
   * 学生
   */
  Student = 1,
  /**
   *  助教
   */
  TeachingAssistant = 2,
  /**
   * 助理
   */
  Assistant = 3,
  /**
   * 管理员
   */
  Admin = 4
}
