
































import { Component, Vue } from 'vue-property-decorator'
import lodash from 'lodash'
import CourseNav from './components/CourseNav.vue'
import CourseTab from './components/CourseTab.vue'
import ContentWaterfall from './components/ContentWaterfall.vue'
import OldMap from './views/tab_map/index.vue'
import FromImgModel from '@xt/www/components/fromImgModel/index.vue'
import ClasshourPopup from './components/classhourPopup/index.vue'
import CourseInfoModal from './components/CourseInfoModal.vue'
import { UserCourseStudyProgress } from '@xt/client/types/course.d'
// import tabsView from './views/tabs.vue'
// import headerView from './views/header.vue'

type TabConfig = {
  name: string
  key: number
  img: string
  current: boolean
  tabParams: {
    tabId?: number
  }
}

@Component({
  layout: 'clean',
  async asyncData(ctx) {
    // 获取用户学习数据
    const studyProgress = await ctx.store.$storeCourse.Details.getUserLearnData(ctx.params.id)
    return { studyProgress }
  },
  async fetch(ctx) {
    const store = ctx.store.$storeCourse
    // 获取我购买的课程ids
    // 加载课程详情数据
    await store.Details.onLoading({
      id: ctx.params.id
    })
    await store.Details.Map.onLoading({
      id: ctx.params.id
    })
  },
  validate({ params }) {
    return /^\d+$/.test(params.id)
  },
  components: {
    // tabsView,
    // headerView
    OldMap,
    CourseNav,
    CourseTab,
    ContentWaterfall,
    FromImgModel,
    ClasshourPopup,
    CourseInfoModal
  }
})
export default class PageView extends Vue {
  studyProgress: UserCourseStudyProgress
  showCourseInfoModal = false
  courseTabConfig: Array<TabConfig> = [
    { name: '发现', key: 0, img: this.$images.dairy_tab_lookup_ico, current: true, tabParams: {} },
    { name: '作业', key: 1, img: this.$images.dairy_tab_homework_ico, current: false, tabParams: { tabId: 1 } },
    { name: '日记', key: 2, img: this.$images.dairy_tab_dairy_ico, current: false, tabParams: { tabId: 2 } }
  ]
  //添加书童
  showAddChild = false
  classhourId: number | null = null

  VisibleAgreement: boolean = false
  type: string = 'Purchase'
  totalHeight: string = ''
  get PageStore() {
    return this.$store.$storeCourse.Details
  }

  get currentTab() {
    return this.courseTabConfig.find(item => item.current === true)
  }

  get dataSource() {
    return this.PageStore.dataSource
  }

  handleShowCourseInfo() {
    this.showCourseInfoModal = true
  }

  handleCloseCourseInfo() {
    this.showCourseInfoModal = false
  }

  onClasshourDetail(options: { classhourId: number }) {
    this.classhourId = options.classhourId
  }

  onShow(type) {
    this.VisibleAgreement = true
    this.type = type.type
  }

  toShowAddChild(isShow: boolean) {
    this.showAddChild = isShow
  }

  handleSwitchTab(key: number) {
    this.courseTabConfig = this.courseTabConfig.map(item => {
      item.current = item.key === key

      return item
    })
  }

  onCloseClasshourPopup() {
    this.classhourId = null
  }

  created() {}
  mounted() {
    const dataSource = lodash.cloneDeep(this.PageStore.dataSource)
    // if (dataSource.courseFullId && this.$store.$global.dev) {
    //   if (
    //     lodash.isEmpty(dataSource.fullLearnEndTime) ||
    //     dataSource.turnSignUpEndTime === 0
    //   ) {
    //     this.$notification.error({
    //       message: "课程数据有误",
    //       description: "没有正确返回全阶课时间数据",
    //     });
    //   }
    // }
  }

  updated() {}
  destroyed() {}
}
