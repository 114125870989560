


























import { Component, Vue, Ref, Prop, Watch, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import LazyImg from '@xt/www/components/lazyImg/index.vue'
import { UploadFileType } from '../index.vue'

@Observer
@Component({
  components: {
    LazyImg
  }
})
export default class PictureBannerDailyComp extends Vue {
  @Prop({ default: () => [] }) fileList: UploadFileType[]
  @Prop({ default: -1 }) currentIndex: number

  @Ref('swiper-wrapper')
  swiperWrapper: HTMLElement

  get HomeworkTaskStore() {
    return this.$store.$storeHomework.HomeworkTask
  }

  get HomeworkTaskDetail() {
    return this.HomeworkTaskStore.HomeworkTaskDetail
  }

  getImageUrl(uploadFile: UploadFileType) {
    return this.ossProcessImg(uploadFile.waterUrl, 1296)
  }

  onPrevSwiper() {
    if (this.currentIndex === 0) return
    this.$emit('toggle-swiper', this.currentIndex - 1)
  }

  onNextSwiper() {
    if (this.currentIndex === this.fileList.length - 1) return
    this.$emit('toggle-swiper', this.currentIndex + 1)
  }

  onImagePreview(index: number) {
    this.$showViewer(
      this.fileList.map(v => this.getImageUrl(v)),
      index
    )
  }

  @Emit('choose-image')
  onChooseImage() {}

  @Watch('currentIndex')
  changeSwiper() {
    if (this.swiperWrapper) {
      this.swiperWrapper.style.transform = `translateX(-${this.$px2rem(this.currentIndex * 648)})`
    }
  }

  created() {}
}
