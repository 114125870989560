



















import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import DailyWriteComp from './DailyWrite.vue'
import DailyDetailComp from './DailyDetail.vue'
import { ContentItem } from './ContentCard.vue'

export type DailyPopupType = 'write' | 'select'

export enum EnumVisibleRange {
  /** 私密 */
  Privacy = 1,
  /** 未知 */
  Unknow = 2,
  /** 公开 */
  Public = 3
}

@Observer
@Component({
  name: 'Classhour',
  components: {
    DailyWriteComp,
    DailyDetailComp
  }
})
export default class DailyPopup extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: 'write' }) dailyPopupType: DailyPopupType
  @Prop() activeDaily: ContentItem

  @Ref('daily-content-comp')
  dailyContentComp: any

  @Emit('close')
  onClose() {}

  @Watch('visible')
  async changeVisible(value: boolean) {
    if (!value) {
      this.dailyContentComp?.onReset()
      return
    }
  }

  created() {}
}
