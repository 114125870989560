




















































import { Prop, Vue, Component, Emit, Watch } from 'vue-property-decorator'
import { EnumPreferentialType, IS_COMPOSE_COUPON } from '@xt/client/enums'
import { BaseCouponItem } from '@xt/client/types/coupon'
import { formatCouponInfo, FormatCouponInfo } from '@xt/client/utils/coupon'

@Component
export default class ExchangeWelfwareConfirmPopup extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: '' }) title: string
  @Prop({ default: () => {} }) couponDetail: BaseCouponItem
  // 兑换类型
  @Prop({ default: 1 }) priceType: 1 | 2
  @Prop({ default: '' }) exchangeCount: number
  @Prop({ default: '' }) leaveCount: number

  couponInfo: FormatCouponInfo | null = null
  isExchanging = false

  get EnumPreferentialType() {
    return EnumPreferentialType
  }

  get canUseWithAnotherCoupon(): boolean {
    return !!(this.couponDetail.composeFlag & IS_COMPOSE_COUPON)
  }

  @Emit('useDesc')
  clickUseDescHandler() {}

  @Emit('close')
  onCancel() {}

  onConfirmExchange() {
    if (this.isExchanging) return
    this.isExchanging = true
    this.$emit('confirmExchange', () => {
      this.isExchanging = false
    })
  }

  @Watch('visible', { immediate: true })
  changeVisible(value: boolean) {
    if (!value) return
    this.couponInfo = formatCouponInfo(this.couponDetail, 'activity')
  }
}
