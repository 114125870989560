




















































































import lodash from 'lodash'
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { FormModel, DatePicker, Cascader } from 'ant-design-vue'
import { dataSource_My } from '@xt/client'
import { countryCascaderFilter, getLocationCascader } from '@xt/client'

@Observer
@Component({
  scrollToTop: true,
  async fetch(ctx) {
    const store = ctx.store.$storeUser
    await store.onGetUserInfo()
  },
  components: { DatePicker, Cascader }
})
export default class PageView extends Vue {
  @Ref('ruleForm') ruleForm: FormModel
  get PageStore() {
    return this.$store.$storeUser
  }
  userForm = onCreateModel()
  rules = getRules.call(this)

  get disabled() {
    return (
      !this.userForm.sex ||
      !this.userForm.industry ||
      !this.userForm.career ||
      !this.userForm.education ||
      !this.userForm.locationCascader.length
    )
  }

  // 地区 配置
  get countryOptions() {
    return this.toJS(this.$store.$location.locationData)
  }
  //  地区 搜索
  countrySearch = { filter: countryCascaderFilter }
  created() {
    lodash.defaults(this, dataSource_My)
    let data = lodash.cloneDeep(this.PageStore.UserInfo)
    if (data.birthday) {
      data.birthday = this.moment(data.birthday)
    }
    let newData = {}
    for (let i in data) {
      newData[i] = !data[i] ? undefined : data[i]
    }
    this.userForm = getLocationCascader(newData)
  }

  async onSubmit() {
    try {
      await this.ruleForm.validate()
      let data = lodash.cloneDeep(this.userForm)
      // 去掉昵称
      lodash.unset(data, 'nickname')
      // 转换生日时间戳
      data.birthday = data.birthday.valueOf()
      // 转换地址
      data = getLocationCascader(data, true)
      this.onUpdate(data)
    } catch (error) {
      console.log('Clin -> file: completeInfo.vue -> line 171 -> PageView -> onSubmit -> error', error)
    }
  }

  async onUpdate(data: any = this.userForm) {
    try {
      await this.PageStore.onUpdateUserInfo(data)
      this.$msg(this.$EnumMessage.save_success, 'success')
      // this.$router.push({
      //   name: "payment",
      //   query: this.PageStore.toPayMentQuery
      // });
    } catch (error) {
      this.$msg(error)
    }
    this.PageStore.onToggleVisible(false)
  }
  updated() {}
  destroyed() {}
}
function onCreateModel() {
  return {
    id: '', //id
    sex: '', // 性别(0 未知 1 男 2女)
    birthday: '', // 生日
    industry: undefined, // 行业
    career: undefined, // 职业
    education: undefined, // 学历
    locationCascader: [],
    city: '', // 城市
    country: '', // 国家
    address: '', // 地址
    location: '', // 所在地（0 未知，1 国内，2国外）
    province: '' // 省份
  }
}
function getRules() {
  return {
    sex: [
      { required: true, message: '请选择性别', trigger: 'change' },
      {
        pattern: /^[1-2]/,
        message: '请选择性别',
        trigger: 'change'
      }
    ],
    birthday: [{ required: true, message: '请输入生日', trigger: 'change' }],
    industry: [{ required: true, message: '请选择行业', trigger: 'change' }],
    career: [{ required: true, message: '请选择职业', trigger: 'change' }],
    education: [{ required: true, message: '请选择学历', trigger: 'change' }],
    locationCascader: [{ required: true, message: '请选择所在地', trigger: 'change' }]
  }
}
