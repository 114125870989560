export * from './about/controller'
export * from './basics/pagination'
export * from './basics/course'
export * from './basics/classhour'
export * from './course/controller'
export * from './home/controller'
export * from './homework/controller'
export * from './order/controller'
export * from './stationery/controller'
export * from './user/controller'
export * from './video/controller'
export * from './my/controller'
export * from './wechat/controller'
export * from './live/controller'
export * from './oss'
export * from './ticket/controller'
export * from './groupbuy/controller'
export * from './product/controller'
export * from './show/controller'
export * from './activitys/controller' //活动
export * from './device/controller'
export * from './gensee'
export * from './coupon'
export * from './location'
