




























import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { StorageWithExpiredTime } from '@xt/client/helpers/storage'
import { BanType, getRandom, StorageKeys } from '@xt/client'
import { reportEntryLog } from '@xt/client/utils/log'
import { uuid } from '@xt/client/utils/uuid'

@Observer
@Component({
  components: {}
})
export default class AccountAlert extends Vue {
  @Prop({ default: false }) visible

  modalId = uuid()

  @Watch('visible', { immediate: true })
  handleVisible(isVisible) {
    const banInfo = this.$store.$storeUser.accountBanInfo

    if (!banInfo) {
      return
    }

    reportEntryLog('account.alert.expose', {
      exposeTime: this.$ajax.serviceDate().valueOf(),
      id: banInfo.id,
      baneType: banInfo.banType,
      page: location.pathname,
      modalId: this.modalId,
      warnFreq: banInfo.warnFreq
    })
  }

  handleConfirm() {
    const banInfo = this.$store.$storeUser.accountBanInfo
    const offset = getRandom(-30, 30)

    if (!banInfo || !banInfo.id) return

    // 如果设置的弹出频次小于30分钟则按照设置的频次为准，大于30分钟则加上一个随机时间
    const warnFreqMinutes = banInfo.warnFreq * 60
    const freq = warnFreqMinutes >= 30 ? warnFreqMinutes + offset : warnFreqMinutes

    if (banInfo.banType === BanType.Warn) {
      const key = StorageKeys.AccountAlertModal
      const storage = new StorageWithExpiredTime(localStorage)
      storage.set(key, banInfo.id, freq * 60 * 1000)
    }

    reportEntryLog('account.alert.confirm', {
      clickTime: this.$ajax.serviceDate().valueOf(),
      offset,
      id: banInfo.id,
      banType: banInfo.banType,
      page: location.pathname,
      modalId: this.modalId
    })

    this.$store.$storeUser.setAccountBanInfo(null)
  }

  openZhichiCustom(e) {
    e.stopPropagation()
    const customNode = document.querySelector('#openZhichiCustom') as HTMLDivElement
    customNode.click()
  }
}
