













import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { LazyImageRandomColors as randomColors } from '@xt/client/helpers/constant'

@Component({
  components: {}
})
class Index extends Vue {
  @Prop() className
  @Prop() src
  @Prop() styles
  @Prop({ default: false }) noBackground
  isError: boolean = false
  fun: () => void
  color: string
  data() {
    return {
      color: !this.noBackground ? randomColors[Math.floor(Math.random() * 6)] : 'rgba(0, 0, 0, 0)'
    }
  }
  @Emit('click')
  click() {}
  @Emit('onLoadImg')
  onLoadImg() {}
  @Emit('onLoadError')
  onLoadError() {}
  destroyed() {}
}

export default Index
