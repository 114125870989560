










































import { Component, Emit, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import ClasshourMainComp from '../ClasshourMain.vue'
import ClasshourViceComp from '../ClasshourVice.vue'
import ClasshourHomeworkViceComp from '../ClasshourHomeworkVice.vue'
import SkeletonComp from './components/skeleton.vue'
import { MixinsCourseNewClasshour } from '@xt/client/mixins'
import { IClasshourTaskDataSource, IMemberHomeworkItem } from '@xt/client'

type ViewType = 'main' | 'vice'

export type ViceType = 'homework_detail' | 'homework_submit'

export type PreviewOptions = {
  type?: 'homework' | 'picture'
  homeworkRequireArea?: boolean
  index?: number
}

export type UploadFileResponse = {
  filePath: string | null
  fileUrl: string | null
  originThumbUrl: string
  originUrl: string
  originalHeight: string
  originalWidth: string
  videoCoverPicThumbUrl: string | null
  videoCoverPicUrl: string | null
  videoUrl: string | null
  waterThumbUrl: string
  waterUrl: string
  originalUrl: string
}

export type UploadFileType = {
  lastModified: number
  lastModifiedDate: Date
  md5: string
  name: string
  originFileObj: File
  percent: number
  response: UploadFileResponse
  size: number
  status: 'uploading' | 'done' | 'error' | 'removed' | 'failed'
  uid: string
  waterUrl: string
  [propName: string]: any
}

@Observer
@Component({
  name: 'Classhour',
  components: {
    ClasshourMainComp,
    ClasshourViceComp,
    ClasshourHomeworkViceComp,
    SkeletonComp
  }
})
export default class ClasshourPopup extends Mixins(MixinsCourseNewClasshour) {
  @Prop({ default: false }) visible: boolean
  @Prop() classhourId: number

  view: ViewType = 'main'
  activeHomeworkTask: IClasshourTaskDataSource | null = null
  activeHomework: IMemberHomeworkItem | null = null
  viceType: ViceType = 'homework_submit'
  dataLoaded = false

  @Ref('vice-view')
  viceView: any

  onToggleView(options: { view: ViewType; viceType?: ViceType; homeworkTask?: IClasshourTaskDataSource; homework?: IMemberHomeworkItem }) {
    const { view, homeworkTask, homework, viceType } = options
    this.view = view
    this.activeHomeworkTask = homeworkTask || null
    this.activeHomework = homework || null
    this.viceType = viceType || 'homework_submit'
  }

  onReset() {
    this.view = 'main'
    this.activeHomework = null
    this.activeHomeworkTask = null
    this.dataLoaded = false
    this.viceType = 'homework_submit'
  }

  @Emit('close')
  onClose() {}

  @Watch('visible')
  async changeVisible(value: boolean) {
    if (!value) {
      this.onReset()
      this.viceView?.onReset()
      return
    }
    try {
      await this.onLoading(this.classhourId)
      setTimeout(() => {
        this.dataLoaded = true
      }, 500)
    } catch (error) {
      this.onClose()
    }
  }

  created() {}
}
