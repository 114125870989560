import { render, staticRenderFns } from "./homework-detail-content.vue?vue&type=template&id=39058172&scoped=true&"
import script from "./homework-detail-content.vue?vue&type=script&lang=ts&"
export * from "./homework-detail-content.vue?vue&type=script&lang=ts&"
import style0 from "./homework-detail-content.vue?vue&type=style&index=0&id=39058172&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39058172",
  null
  
)

export default component.exports