





import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lottie, { AnimationItem, BMCompleteEvent, BMDestroyEvent, BMCompleteLoopEvent } from 'lottie-web'
import { LottieSucces, LoadStatus } from '@xt/client/types/lottie'
import { reportSumLog } from '@xt/client/utils/log'

@Observer
@Component({
  name: 'LottieAnimation'
})
export default class LottieComponent extends Vue {
  @Prop({ required: true }) width: number
  @Prop({ required: true }) height: number
  @Prop({ default: false }) loop: boolean
  @Prop({ default: false }) autoplay: boolean
  @Prop({}) assetsPath: string
  @Prop({ required: true }) path: string
  @Prop({ required: true }) fallbackImage: string

  loadStatus: LoadStatus = 'loading'
  lottieInstance: null | AnimationItem = null

  created() {}

  play() {
    if (this.lottieInstance) {
      if (!this.lottieInstance.isPaused) {
        console.warn('Lottie 动画还在运动')
        return
      }

      console.warn('Lottie run play', this.lottieInstance.getDuration(true))

      this.lottieInstance.play()
    }
  }

  pause() {
    if (this.lottieInstance) {
      console.warn('Lottie run pause')
      this.lottieInstance.pause()
    }
  }

  reset() {
    if (this.lottieInstance) {
      console.warn('Lottie run goToAndStop 0')
      this.lottieInstance.goToAndStop(0, true)
    }
  }

  mounted() {
    const container = this.$refs['lottie-anim-root'] as HTMLDivElement

    // never
    if (!container) {
      console.error('Lottie Root Animation Node Not Found!', container)
      reportSumLog('lottie.mount.error')
      this.loadStatus = 'fail'
      return
    }

    this.lottieInstance = lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop: this.loop,
      autoplay: this.autoplay,
      assetsPath: this.assetsPath,
      path: this.path
    })

    this.lottieInstance.addEventListener('loaded_images', this.onLoadedImages)
    this.lottieInstance.addEventListener('error', this.onError)
    this.lottieInstance.addEventListener('complete', this.onComplete)
    this.lottieInstance.addEventListener('loopComplete', this.onLoopComplete)
    this.lottieInstance.addEventListener('config_ready', this.onConfigReady)
    this.lottieInstance.addEventListener('data_ready', this.onDataReady)
    this.lottieInstance.addEventListener('data_failed', this.onDataFailed)
    this.lottieInstance.addEventListener('destroy', this.onDestroy)
  }

  destroyed() {
    if (this.lottieInstance) {
      this.lottieInstance.removeEventListener('loaded_images', this.onLoadedImages)
      this.lottieInstance.removeEventListener('error', this.onError)
      this.lottieInstance.removeEventListener('complete', this.onComplete)
      this.lottieInstance.removeEventListener('loopComplete', this.onLoopComplete)
      this.lottieInstance.removeEventListener('config_ready', this.onConfigReady)
      this.lottieInstance.removeEventListener('data_ready', this.onDataReady)
      this.lottieInstance.removeEventListener('data_failed', this.onDataFailed)
      this.lottieInstance.removeEventListener('destroy', this.onDestroy)
    }
  }

  onLoadedImages() {
    console.warn('Lottie onLoadedImages Event')
    this.$emit('loaded-images')
  }

  onError() {
    this.loadStatus = 'fail'
    console.warn('Lottie onError Event')
    this.$emit('error')
    reportSumLog('lottie.comp.error')
  }

  onComplete(data: BMCompleteEvent) {
    console.warn('Lottie onComplete Event', data)
    this.$emit('complete', data)
  }

  onLoopComplete(data: BMCompleteLoopEvent) {
    console.warn('Lottie onLoopComplete Event', data)
    this.$emit('loop-complete', data)
  }

  onConfigReady() {
    console.warn('Lottie onConfigReady Event')
    this.$emit('config-ready')
  }

  onDataReady() {
    console.warn('Lottie onDataReady Event')
    this.loadStatus = 'success'
    const options: LottieSucces = { lottie: this.lottieInstance, play: this.play, pause: this.pause, reset: this.reset }

    this.$emit('data-ready', options)
    reportSumLog('lottie.data-ready.debug')
  }

  onDataFailed() {
    this.loadStatus = 'fail'
    console.warn('Lottie onDataFailed Event')
    this.$emit('data-failed')
    reportSumLog('lottie.comp.data-failed')
  }

  onDestroy(data: BMDestroyEvent) {
    console.warn('Lottie onDestroy Event', data)
    this.$emit('destroy', data)
  }
}
