




























import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { getStickerPath, getStickerCoverPath, getStickerPattern } from '@xt/client/utils/sticker'

@Observer
@Component({
  name: 'StickerSelect'
})
export default class StickerSelect extends Vue {
  @Prop({ required: true }) visible: boolean
  @Prop({ default: 4 }) col: number

  stickerIndex = 0

  stickerConfig = [
    {
      name: 'group-1',
      sticker: ['1', '2', '3', '4']
    },
    {
      name: 'group-2',
      sticker: ['1']
    }
  ]

  get stickerGroupName() {
    return this.stickerConfig[this.stickerIndex].name
  }

  get sticker() {
    return lodash.chunk(this.stickerConfig[this.stickerIndex].sticker, this.col)
  }

  getStickerImage(groupName: string, stickerName: string) {
    return getStickerPath(groupName, stickerName)
  }

  getStickerCover(groupName: string) {
    return getStickerCoverPath(groupName)
  }

  selectStickerItem(groupName: string, stickerName: string) {
    this.$emit('choose', getStickerPattern(groupName, stickerName))
  }

  changeStickerGroup(index: number) {
    this.stickerIndex = index
  }

  created() {}

  mounted() {}

  destroyed() {}
}
