













































import { Component, Vue, Emit, Ref, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import PictureBannerDailyComp from './classhourPopup/components/picture-banner-daily.vue'
import PictureThumbDailyComp from './classhourPopup/components/picture-thumb-daily.vue'
import UploadComp from './classhourPopup/components/upload.vue'
import HomeworkSubmitComp from './classhourPopup/components/homework-submit.vue'
import { PreviewOptions, UploadFileType } from './classhourPopup/index.vue'
import { EnumVisibleRange } from './DailyPopup.vue'

@Observer
@Component({
  components: {
    UploadComp,
    HomeworkSubmitComp,
    PictureBannerDailyComp,
    PictureThumbDailyComp
  }
})
export default class DailyWriteComp extends Vue {
  @Prop({ default: false }) isBack: boolean

  currentIndex = -1
  fileList: Array<UploadFileType> = []
  dragging = false
  publishLock = false
  draggingThumb = false

  @Ref('upload-box')
  uploadBox: HTMLDivElement

  @Ref('homework-submit-comp')
  homeworkSubmitComp: any

  get HomeworkTaskStore() {
    return this.$store.$storeHomework.HomeworkTask
  }

  get MomentStore() {
    return this.$store.$storeHomework.SunDrying
  }

  get pictureRequired() {
    return true
  }

  get sourceId() {
    return lodash.get(this.$route.params, 'id')
  }

  onChangeDragging(dragging: boolean) {
    if (this.draggingThumb) return
    this.dragging = dragging
  }

  onChangeDraggingStatus(dragging: boolean) {
    this.draggingThumb = dragging
  }

  onUploadChange(fileList: UploadFileType[]) {
    this.fileList = fileList
    if (this.currentIndex < 0) {
      this.currentIndex = 0
    }
  }

  onStopPropagation(e: Event) {
    e.stopPropagation()
  }

  @Emit('toggle-view')
  goBack() {
    this.onReset()
    return { view: 'main' }
  }

  onToggleSwiper(index: number) {
    this.currentIndex = index
  }

  onRemovePicture(file: UploadFileType) {
    const fileList = [...this.fileList]
    this.fileList = fileList.filter(item => item.uid !== file.uid)
    if (!this.fileList.length) {
      this.currentIndex = -1
    } else if (this.currentIndex > this.fileList.length - 1) {
      this.currentIndex = this.fileList.length - 1
    }
  }

  onThumbPreview(options: PreviewOptions) {
    const { index } = options
    this.onToggleSwiper(index)
  }

  onChooseImage() {
    this.uploadBox?.click()
  }

  async onPublish(data: any) {
    console.log('onPublish', data)
    if (this.publishLock) return
    try {
      this.publishLock = true
      await this.MomentStore.onInstall({
        title: data.title,
        content: data.content,
        contentLength: data.contentLength,
        pictures: data.pictures,
        visibleRange: data.public ? EnumVisibleRange.Public : EnumVisibleRange.Privacy,
        sourceId: this.sourceId
      })
      this.$msg('发布成功', 'success')
      data.onReset()
      this.publishComplate()
    } catch {
      this.$msg('发布失败', 'error')
    }
    this.publishLock = false
  }

  publishComplate() {
    this.$emit('publish-finish')
    this.onReset()
  }

  onReset() {
    this.currentIndex = -1
    this.dragging = false
    this.fileList = []
    this.publishLock = false
    this.homeworkSubmitComp?.onReset()
  }

  created() {}
}
