





















import { Observer } from 'mobx-vue'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class PlaybackPopup extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: '' }) content: string
  @Prop({ default: '确认' }) btnText: string
  @Prop({ default: () => ({ url: '', width: 0, height: 0 }) }) imgInfo: { url: string; width: number; height: number; marginTop: number }

  get imgStyle() {
    return {
      width: this.$px2rem(this.imgInfo.width),
      height: this.$px2rem(this.imgInfo.height),
      marginTop: this.$px2rem(this.imgInfo.marginTop)
    }
  }

  @Emit('confirm')
  confirmHandler() {}

  @Emit('cancel')
  cancelHandler() {}
}
