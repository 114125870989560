































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'
import { Classhour } from '@xt/client'
import { EnumQABizType, EnumQAQuestionNode } from '@xt/client/entities/course/map'

@Observer
@Component({
  components: {
    Teleport
  }
})
export default class QuestionComp extends Vue {
  @Prop() dataSource: Classhour

  get ClasshourStore() {
    return this.$store.$storeCourse.Details.Map.Details
  }

  get liveEnd() {
    return this.dataSource.original.liveEnd
  }

  visible = false
  questionContent = ''

  onCancel() {
    this.visible = false
    this.onReset()
  }

  async onSubmitQuestion() {
    console.log('onSubmitQuestion', this.questionContent)
    if (!this.questionContent.trim().length) {
      this.$msg('请输入问题')
      return
    }

    try {
      await this.ClasshourStore.onClasshourQA({
        bizId: this.dataSource.id,
        bizType: EnumQABizType.Classhour,
        questionContent: this.questionContent,
        questionNode: EnumQAQuestionNode.Classhour,
        sourceDescription: this.dataSource.name
      })
      this.$msg('提交成功', 'success')
    } catch (error) {}

    this.onCancel()
  }

  onReset() {
    this.questionContent = ''
  }
}
